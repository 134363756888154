import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { configStyle } from '../services/configStyle';
import { actualizarEstado } from '../services/api.js';

const CancelarFlujo = () => {

    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [apiKey, setApiKey] = useState('')


    const history = useHistory();
    const { ruta } = useParams();


    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }


    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])


    const continuarFlujo = () => {
        if(location.state){
            if (location.state.passport) {
                history.push({
                    pathname: '/' + ruta,
                    state: { passport: true  }
                })
            }else{
                history.push('/' + ruta)
            }
        }else{
            history.push('/' + ruta)
        }
    }

    const salirFlujo = () => {
        statusE();
        //history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
    }

    const statusE = () => {
        //console.log("entre a status cancelar");
        let id_t = localStorage.getItem("uuidTrx")
        let otorgante_t = localStorage.getItem("apikeyOtorgante");
        let uuid_t = localStorage.getItem("uuidUser")
        let event_t = "incompleted"
        actualizarEstado(id_t, otorgante_t, uuid_t, event_t).then((data) => {
            if (data.status === 200) {
                //console.log("Se actualizo status");
            }
        }).catch(() => {
            //console.log("algun error");
            //setLoading(false)
        })
        if (window.opener) {
          window.opener.postMessage(JSON.stringify({
            step: "incompleted",
            result: "FAIL",
          }), localStorage.getItem("externalReference"))
          window.close()
        }
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
          }, 300);
      }

    return (
        <div className="container">
            <div className="row" style={{ height: '100vh' }}>
                <div className="col-2"></div>
                <div className="col">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="card text-right">
                        <div className="card-body">
                            <h5 className="card-title">Cancelar proceso</h5>
                            <p className="card-text">En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            <button type="button" className="btn btn-secondary" onClick={continuarFlujo}>NO</button>
                            <button type="button" className="btn btn-raised btn-primary main_bg_color" onClick={salirFlujo}>SI</button>
                        </div>
                    </div>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
    )

}

export default CancelarFlujo