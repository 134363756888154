import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { actualizarEstado, getDocuments } from '../services/api.js';
import { sendData } from '../services/data';

const Finalizado = () => {
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState("")
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [hasPhone, setHasPhone] = useState(false)

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        
        if (localStorage.getItem("movil")) {
            setHasPhone(true)
        }

        //localStorage.setItem("completado", "true");
        setTimeout(() => {
            dataLinks()
        }, 200);  
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])
    

    const dataLinks = async () => {
        //console.log(event_t);
        getDocuments().then((response) => {
            if (response.status === 200) {
                if (response.data.payload.links) {
                    let asd = JSON.stringify(response.data.payload.links)
                    localStorage.setItem('links', asd);
                }
                finalUpdateStatus();
            }
        }).catch(() => {
            //console.log("algun error documents");
            //setLoading(false)
            finalUpdateStatus();
        })
    }

    const status = () => {
        console.log("banderaAmarilla:", hasPhone);
        if (window.opener) {
            let uuidUser = localStorage.getItem("uuidUser");////uuid.split("-")[0]
            let event_t = "OK"
            let res = convertdataJSON();
            //console.log("entre a status finish", uuidUser);
            if(localStorage.getItem("movil")){
                event_t = (res !== "") ? JSON.stringify(res) : ""
                window.opener.postMessage(JSON.stringify({
                    step: "completed",
                    result: "FAIL",
                    data: event_t
                }), localStorage.getItem("externalReference"))
            }else{
                //event_t = (res !== "") ? JSON.stringify(res) : ""
                window.opener.postMessage(JSON.stringify({
                    step: "completed",
                    result: event_t,
                    uuid: uuidUser,
                    data: (res !== "") ? JSON.stringify(res) : "",
                }), localStorage.getItem("externalReference"))
            }

            setTimeout(() => {
                window.close();
            }, 2000);
        }
    }

    const finalUpdateStatus = async () => {
        let res = convertdataJSON();
        let event_t = JSON.stringify(res)
        var json_final = {};
        let statu = "OK"
        if(hasPhone){
            statu = "FAIL"
        }
        json_final = {
            "status": statu,
            "step": "finished",
            "description": event_t,
            "Completo": true
        }
        let event = JSON.stringify(json_final);
        sendData({'step': 'finalizado', 'event': event});
        localStorage.setItem("completado", "true");
        status();
    }

    const convertdataJSON = () => {
        var jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || "";
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || "";
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || "";
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || "";
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || "";
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || "";
        if(hasPhone){
            jsonObj.number_phone = JSON.parse(localStorage.getItem("movil")) || ""
        }
        //var res = JSON.stringify(jsonObj)
        //console.log(res);
        return jsonObj;
    }

    return (
        <div className="main_gradient">
            <Header ruta="finalizado" />
            <div className="main_text_container">
                {
                    (hasPhone) ? 
                    <h1 className="animated">Proceso no exitoso</h1>
                    :
                    <h1 className="animated">¡Muchas Gracias!</h1>
                }
                <br />
                <br />
                    {
                        (!hasPhone) ? 
                            <p className="animated">Tu proceso fue <b>ejecutado con éxito</b>, si no <b>regresaste</b> automáticamente a la web o aplicación original presiona <b>volver al origen</b> o en su defecto <b>cerrar</b> esta ventana.</p>
                            :
                            <p className="animated">Tu proceso esta siendo procesado, si no <b>regresaste</b> automáticamente a la web o aplicación original presiona <b>volver al origen</b> o en su defecto <b>cerrar</b> esta ventana.</p>
                    }
            </div>
            <div className="main_icon_container">
                <br /><br />
                <a id="linkApp" href="#">
                    {(hasPhone ? <img src={process.env.PUBLIC_URL + 'images/warning_36.png'} alt="" /> : <img src={(obtenerValorConfig(dataOtorgante, 'ICONO_ID_CHECK', "") != "") ? obtenerValorConfig(dataOtorgante, 'ICONO_ID_CHECK', process.env.PUBLIC_URL + '/images/check_36.png') : process.env.PUBLIC_URL + '/images/check_36.png'} alt="" />)}
                </a>
                <br /><br />
                <p className="text-center" >Folio: <br />
                    {(hasPhone ? <b>Pendiente</b> : <b>{uuid.split("-")[0]}</b>)
                    }
                </p>
                <br />
                {
                    (localStorage.getItem("package")) ?
                        null
                        :
                        (!window.opener) ?
                            <div className="action_buttons fadeIn delay-1s">
                                <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animated delay-3s" href={"https://" + window.location.host + "/" + localStorage.getItem("apikeyOtorgante")}>
                                    Finalizar
                            </a>
                            </div>
                            :
                            null
                }
            </div>
            <Footer />
        </div>
    )

}

export default Finalizado