/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import Loader from "../components/loader";
import LoaderOCR from "../components/loaderOCR";
import { obtenerOCR } from '../services/api.js';
import { isIOS, isMobileSafari, isMobile, withOrientationChange, isIPad13 } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { hayExcepcion, status, statusError, statusData, statusReintento } from '../services/data'

class CameraPhotoIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        uuid: null,
        dataUser: [],
        dataOtorgante: [],
        dataOCRFront: {},
        isStart: false,
        side: "front",
        proccessing: false,
        proccessingHolo: false,
        desktop: false,
        altura: 960,
        ancho: 720,
        showIdOverlay: false,
        intentsFront: 0,
        intentsBack: 0,
        apikey: "",
        hasResponse: false,
        hasFace: false,
        hasTextFront: false,
        hasTextBack: false,
        isPassport: true,
        isHoloInstructions: true,
        showHoloAnimation: true,
        intentsData: 0,
        errorF: "",
        errorMsg: false,
        errorVideo: false,
        streamStarted: false,
        pathname: ""
    }

    componentDidMount() {
        const component = this;
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }
        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intentsFront = Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"));
                //console.log("intentsFront: " + this.state.intentsFront);
            }
        }
        this.state.pathname = window.location.pathname;
        //console.log("bloque: " + bloqueo);
        if (!isMobile) {
            //console.log("es desktop");
            this.state.desktop = true;
            this.state.altura = 720;
            this.state.ancho = 960;
            /*  var element = document.getElementById("myVideo");
             element.classList.add("myVideo_rotate"); */
            this.setState({ showHoloAnimation: false })
            this.setState({ isHoloInstructions: false, isStart: true })
            var element = document.getElementById("video_wrt");
            element.classList.add("myVideo_rotate");
        } else {
            if (hayExcepcion()) {
                this.setState({ isHoloInstructions: false, isStart: true, showHoloAnimation: false })
            }
        }

        this.state.isPassport = this.props.location.state.passport;
        /*
        if(this.state.isPassport){
            var jsonObj = {"type_id":"PASAPORTE"};
            statusData("captura_id", jsonObj)
            //console.log("pasaporte");
        }else{
            var jsonObj = {"type_id":"INE"};
            statusData("captura_id", jsonObj)
            //console.log("ine");
        }
        */
        var deviceFuncional = "";
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                if (device.kind === 'videoinput') {
                    //console.log(device.label)
                    if ((device.label).includes("back")) {
                        if ((device.label).includes("0")) {
                            deviceFuncional = device.deviceId;
                            //console.log("deviceFuncional1", deviceFuncional);
                        }
                    }
                }
            }
            component.start(deviceFuncional);
        });
        //console.log("deviceFuncional2", deviceFuncional);
        status("captura_id_page", "Captura ID")
    }

    start = (deviceFuncional) => {
        const that = this;
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const constraints = {
            audio: false,
            video: (deviceFuncional) ? {
                deviceId: { exact: deviceFuncional },
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            } : {
                facingMode: "environment",
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            }
        };

        //console.log("Reconfiguracion",constraints);
        that.localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch(
                (handleError) => {
                    //console.log("handleError",handleError)
                    that.setState({ errorVideo: true })
                }
            );

        that.canvas = document.querySelector('canvas');
        that.width = 320;
        that.height = 0;
        that.video = document.querySelector('video');
        that.elemento = document.getElementById("cuadro");
        that.cuenta = document.getElementById("numeros");
        that.titulo = document.getElementById("head_shop");
        let component = that;
        that.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    //console.log("disponibles");
                    component.setState({ streamStarted: true, errorVideo: false })
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            that.setState({ errorVideo: true })
        })

    }

    cancelarCapturaIdentificacion = () => {
        this.props.history.push("/preparacion_identificacion")
    }

    enviarFotoHolograma = async () => {
        this.setState({ loading: true, hasResponse: false })
        const that = this
        const connectionClient = new ConnectionClient();
        let peerConnection = null;
        const localVideo = document.getElementById('video_wrt');
        localVideo.autoplay = true;
        localVideo.muted = true;
        async function beforeAnswer(peerConnectionH) {
            that.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => peerConnectionH.addTrack(track, mediaStream));
            })
            peerConnection = peerConnectionH;
            let dataChannel = null;
            let interval = null;
            let timeoutt = null
            function onMessage({ data }) {
                const responseWRT = JSON.parse(data);
                //console.log(responseWRT);
                peerConnectionH.close()
                that.setState({ isHoloInstructions: false, proccessingHolo: false })
                that.setState({ showHoloAnimation: false })
            }
            let intentos = 0
            function onDataChannel({ channel }) {
                if (channel.label !== 'ping-pong') {
                    return;
                }
                dataChannel = channel;
                dataChannel.addEventListener('message', onMessage);
                interval = setInterval(() => {
                    intentos++;
                    if (intentos === 5) {
                        that.setState({ proccessingHolo: true, loading: false })
                        dataChannel.send("finish_record");
                    } else if (intentos === 60) {
                        that.setState({ isHoloInstructions: false, proccessingHolo: false })
                        peerConnectionH.close()
                    }
                }, 1000);
                timeoutt = setTimeout(() => {
                    let contenedor = (window.location.hostname).includes("proddicio") ? "dco-bucket-prod" : (window.location.hostname).includes("dev.devdicio") ? "dco-bucket-qa" : "dco-bucket-dev";
                    dataChannel.send(JSON.stringify({ uuid: that.state.uuid, side: that.state.side, bucket: contenedor, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "HOLOGRAMA", isMobile: isMobile, isPassport: (that.state.isPassport).toString(), key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")) }));
                }, 500);
            }
            peerConnectionH.addEventListener('datachannel', onDataChannel);
            const { close } = peerConnectionH;
            peerConnectionH.close = function () {
                if (dataChannel) {
                    dataChannel.removeEventListener('message', onMessage);
                }
                if (interval) {
                    clearInterval(interval);
                }
                if (timeoutt) {
                    clearTimeout(timeoutt)
                }
                return close.apply(this, arguments);
            };
        }

        peerConnection = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
        window.peerConnection = peerConnection;

    }



    takeFotoFront = () => {
        //console.log("en foto");
        setTimeout(() => {
            //console.log("tomando foto");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                //obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari)
                that.setState({ proccessing: true, loading: false })
                obtenerOCR(that.state.uuid, blob, "front", isIOS, isMobileSafari)
                    .then((response) => {
                        if (response.status === 200) {
                            //console.log("200","nuevoOCR");
                            let fechaFinal = response.data.payload.ocrData.fechaNacimiento
                            let fechaOcr = '';
                            if (fechaFinal) {
                                if (fechaFinal.length > 6 && fechaFinal.length < 10) {
                                    let añoActualInt = new Date().getFullYear().toString().substr(-2);
                                    let añoFechaOcr = fechaFinal.substr(-2);
                                    let añoFechaOcrInt = fechaFinal.substr(-2);
                                    let fechaSinAño = fechaFinal.substr(0, 6);
                                    if (añoFechaOcrInt >= añoActualInt) {
                                        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
                                    } else {
                                        let mayoriaDeEdad = añoActualInt - 18;
                                        if (añoFechaOcrInt <= mayoriaDeEdad) {
                                            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                                        } else {
                                            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                                        }
                                    }
                                } else if (fechaFinal.length < 6) {
                                    fechaOcr = ""
                                } else {
                                    fechaOcr = fechaFinal
                                }
                            }
                            that.setState({
                                dataOCRFront: {
                                    nombreCompleto: response.data.payload.ocrData.nombreCompleto,
                                    curp: response.data.payload.ocrData.curp,
                                    dni: response.data.payload.ocrData.dni,
                                    claveElector: response.data.payload.ocrData.claveElector,
                                    estado: response.data.payload.ocrData.estado,
                                    municipio: response.data.payload.ocrData.municipio,
                                    seccion: response.data.payload.ocrData.ineSeccion,
                                    colonia: response.data.payload.ocrData.colonia,
                                    calle: response.data.payload.ocrData.calle,
                                    cp: response.data.payload.ocrData.cp,
                                    numero: response.data.payload.ocrData.numero,
                                    anioRegistro: response.data.payload.ocrData.anioRegistro,
                                    numeroRegistro: response.data.payload.ocrData.numeroRegistro,
                                    ineRegistro: response.data.payload.ocrData.ineRegistro,
                                    emision: response.data.payload.ocrData.ineEmision,
                                    vigencia: response.data.payload.ocrData.ineVigencia,
                                    fechaNacimiento: fechaOcr,
                                    sexo: response.data.payload.ocrData.sexo,
                                    apellidoPaterno: response.data.payload.ocrData.apellidoPaterno,
                                    apellidoMaterno: response.data.payload.ocrData.apellidoMaterno,
                                    nombres: response.data.payload.ocrData.nombres,
                                    identificacionElectoral: response.data.payload.ocrData.identificacionElectoral,
                                    documentoNacionalPeru: response.data.payload.ocrData.documentoNacionalPeru,
                                    identificacionMigratoria: response.data.payload.ocrData.identificacionMigratoria,
                                    identificacionPasaporte: response.data.payload.ocrData.identificacionPasaporte,
                                }
                            })
                            localStorage.setItem("ocrFront", JSON.stringify({
                                nombreCompleto: response.data.payload.ocrData.nombreCompleto,
                                curp: response.data.payload.ocrData.curp,
                                dni: response.data.payload.ocrData.dni,
                                claveElector: response.data.payload.ocrData.claveElector,
                                estado: response.data.payload.ocrData.estado,
                                municipio: response.data.payload.ocrData.municipio,
                                seccion: response.data.payload.ocrData.ineSeccion,
                                colonia: response.data.payload.ocrData.colonia,
                                calle: response.data.payload.ocrData.calle,
                                cp: response.data.payload.ocrData.cp,
                                numero: response.data.payload.ocrData.numero,
                                ineRegistro: response.data.payload.ocrData.ineRegistro,
                                anioRegistro: response.data.payload.ocrData.anioRegistro,
                                numeroRegistro: response.data.payload.ocrData.numeroRegistro,
                                emision: response.data.payload.ocrData.ineEmision,
                                vigencia: response.data.payload.ocrData.ineVigencia,
                                fechaNacimiento: fechaOcr,
                                sexo: response.data.payload.ocrData.sexo,
                                apellidoPaterno: response.data.payload.ocrData.apellidoPaterno,
                                apellidoMaterno: response.data.payload.ocrData.apellidoMaterno,
                                nombres: response.data.payload.ocrData.nombres,
                                identificacionElectoral: response.data.payload.ocrData.identificacionElectoral,
                                documentoNacionalPeru: response.data.payload.ocrData.documentoNacionalPeru,
                                identificacionMigratoria: response.data.payload.ocrData.identificacionMigratoria,
                                identificacionPasaporte: response.data.payload.ocrData.identificacionPasaporte,
                            }))
                            //console.log("guarde");
                            var tipo = ""
                            if (that.state.dataOCRFront.identificacionElectoral) {
                                tipo = "INE";
                            }
                            else if (that.state.dataOCRFront.identificacionPasaporte) {
                                tipo = "PASAPORTE";
                                that.setState({ isPassport: true });
                            }
                            else if (that.state.dataOCRFront.documentoNacionalPeru) {
                                tipo = "DNI_PERU";
                            }
                            else if (that.state.dataOCRFront.identificacionMigratoria) {
                                tipo = "FMM";
                            }
                            else {
                                tipo = "INE";
                            }
                            var jsonObj = { "type_id": tipo };
                            statusData("captura_id", jsonObj)
                            //console.log(("tipo",tipo));
                            if (tipo === "PASAPORTE") {
                                that.setState({ loading: false, proccessing: false, hasTextBack: true })
                                that.sendData()
                                setTimeout(() => {
                                    that.props.history.push({
                                        pathname: '/resultados_identificacion',
                                        state: { passport: false }
                                    })
                                }, 300);
                            } else {
                                let dataOCRFrontLS = localStorage.getItem("ocrFront")
                                const ocrFront = JSON.parse(dataOCRFrontLS)
                                //console.log(ocrFront);
                                if (ocrFront.nombreCompleto && (ocrFront.curp || ocrFront.dni)) {
                                    if (ocrFront.dni) {
                                        if (ocrFront.dni.length < 3 || ocrFront.nombreCompleto.length < 10) {
                                            if (that.state.intentsData === 2) {
                                                localStorage.setItem("intentsData", "OCR");
                                                that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                            } else {
                                                that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos no cumplen con formato", hasResponse: true })
                                                that.statusR();
                                            }
                                        } else {
                                            that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                        }
                                    } else {
                                        if (ocrFront.curp.length < 16 || ocrFront.nombreCompleto.length < 10) {
                                            if (that.state.intentsData === 2) {
                                                localStorage.setItem("intentsData", "OCR");
                                                that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                            } else {
                                                that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos no cumplen con formato", hasResponse: true })
                                                that.statusR();
                                            }
                                        } else {
                                            that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                        }
                                    }
                                } else {
                                    if (that.state.intentsData === 2) {
                                        localStorage.setItem("intentsData", "OCR");
                                        that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0 })
                                    } else {
                                        that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos incompletos", hasResponse: true })
                                        that.statusR();
                                    }
                                    ////console.log("intentsData",intentsData);
                                }
                            }
                            if (that.state.intentsFront === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) && that.state.hasFace) {
                                if (tipo === "PASAPORTE") {
                                    that.setState({ loading: false, proccessing: false, hasTextBack: true })
                                    that.sendData()
                                    setTimeout(() => {
                                        that.props.history.push({
                                            pathname: '/resultados_identificacion',
                                            state: { passport: false }
                                        })
                                    }, 300);
                                } else {
                                    that.setState({ loading: false, proccessing: false, side: "back" })
                                }
                            }

                            that.setState({ loading: false, proccessing: false, hasResponse: true })
                        } else {
                            //console.log("otreo");
                            that.setState({ loading: false, proccessing: false, intentsFront: that.state.intentsFront + 1, errorF: response.data.message, hasResponse: true })
                            if (that.state.intentsFront === 3)
                                that.statusF();
                            else
                                that.statusR();
                            that.setState({ errorMsg: true })
                        }
                    })
                    .catch((error) => {
                        that.setState({ errorMessage: "Por favor ubica tu identificación en el área marcada para continuar.", intentsFront: that.state.intentsFront + 1, errorToken: true, errorF: "Error en la captura de la identificación", token: null, loading: false, proccessing: false, hasResponse: true })
                        if (that.state.intentsFront === 3)
                            that.statusF();
                        else
                            that.statusR();
                    }
                    )
            }, 'image/png', 0.9);
        }, 1000);
    }

    takeFotoBack = () => {
        //console.log("en foto back");
        setTimeout(() => {
            //console.log("tomando foto back");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                //obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari)
                that.setState({ proccessing: true, loading: false })
                obtenerOCR(that.state.uuid, blob, "back", isIOS, isMobileSafari)
                    .then((response) => {
                        if (response.status === 200) {
                            //console.log("200-back",response.data);
                            that.setState({
                                dataOCRBack: {
                                    cadena: response.data.payload.ocrData.cadena,
                                    ineVigencia: response.data.payload.ocrData.ineVigencia,
                                    cicMRZ: response.data.payload.ocrData.cicMRZ,
                                    ciudadanoMRZ: response.data.payload.ocrData.ciudadanoMRZ,
                                    nombreCompletoMRZ: response.data.payload.ocrData.nombreCompletoMRZ,
                                    seccionMRZ: response.data.payload.ocrData.seccionMRZ

                                }
                            })
                            localStorage.setItem("ocrBack", JSON.stringify({
                                cadena: response.data.payload.ocrData.cadena,
                                ineVigencia: response.data.payload.ocrData.ineVigencia,
                                cicMRZ: response.data.payload.ocrData.cicMRZ,
                                ciudadanoMRZ: response.data.payload.ocrData.ciudadanoMRZ,
                                nombreCompletoMRZ: response.data.payload.ocrData.nombreCompletoMRZ,
                                seccionMRZ: response.data.payload.ocrData.seccionMRZ
                            }))
                            //console.log("guarde");
                            that.setState({ loading: false, proccessing: false, hasTextBack: true })
                            that.sendData()
                            setTimeout(() => {
                                that.props.history.push({
                                    pathname: '/resultados_identificacion',
                                    state: { passport: false }
                                })
                            }, 300);
                        } else {
                            that.setState({ loading: false, proccessing: false, intentsBack: that.state.intentsBack + 1, errorF: "Error en la captura", hasResponse: true })
                            that.statusR();
                            if (that.state.intentsBack === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"))) {
                                that.sendData()
                                setTimeout(() => {
                                    that.props.history.push({
                                        pathname: '/resultados_identificacion',
                                        state: { passport: false }
                                    })
                                }, 300);
                            }
                        }
                    })
                    .catch((error) => {
                        that.setState({ errorMessage: "Por favor coloca la parte de atras de tu identificación.", errorToken: true, errorF: "Error en la captura de identificación", token: null, proccessing: false, loading: false, hasResponse: true })
                        that.statusR()
                    }
                    )
            }, 'image/png', 0.9);
        }, 1000);
    }

    initRecord = (side) => {
        this.setState({ side: side, loading: true })
        if (side === "front") {
            //this.enviarFotoFront()
            this.takeFotoFront()
        } else {
            //this.enviarFotoBack()
            this.takeFotoBack()
        }
    }

    initRecordHologram = (side) => {
        //console.log("recording for hologram");
        this.enviarFotoHolograma();
        this.setState({ showHoloAnimation: true })
    }
    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("Se quito componente video");
        });
    }

    sendData = () => {
        var jsonObj = {};
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        statusData("captura_id_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    statusF = () => {
        //console.log("entre a status ine");
        let id = (this.state.isPassport) ? "Pasaporte" : "INE"
        statusError("Captura ID", id + ":" + this.state.errorF, "error")
    }

    intentosBloqueo = () => {
        localStorage.setItem("bloqueo", "true");
        let id = (this.state.isPassport) ? "Pasaporte" : "INE"
        statusError("Captura ID", id + ":" + this.state.errorF, "cancelado")
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        setTimeout(() => {
            window.close()
        }, 300);
    }

    checkCamera = () => {
        setTimeout(() => {
            //console.log("Camera:"+this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    statusR = () => {
        statusReintento("Captura ID", this.state.errorF, "RETRY");
    }

    render() {
        const { isLandscape } = this.props;
        //if (isLandscape) { //console.log("landscape"); }
        return (
            <div>
                {(this.state.isStart && !this.state.isHoloInstructions) ? <Fragment>
                    <div className="overlay_box">
                        <div className="alert_box">
                            {(this.state.isPassport) ? (this.state.desktop) ? <p className="animated slideInDown">Por favor coloca la parte de <b>abajo</b> de tu pasaporte, mantenla <b>enfocada</b> dentro de la <b>guía</b> y sigue las instrucciones.</p>
                                : <div>
                                    <div className="center_info_bx">
                                        <p className="centerText">
                                            <img src={process.env.PUBLIC_URL + '/images/pass_scan.svg'} height="100" alt="" />
                                            <span className="izquierdaText">
                                                <br />
                                                Sólo necesitas escanear la sección baja de la identificación que contiene tus datos personales.
                                                <br />
                                                <small>Procura no obstruir ningún dato o tu fotografía</small>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                : (this.state.desktop) ? <p className="animated slideInDown">Por favor coloca el <b>frente</b>, de tu identificación, mantenla <b>enfocada</b> dentro de la <b>guía</b> y sigue las instrucciones.</p> : <p className="animated slideInDown">Por favor coloca tu identificación sobre una superficie <b>negra u obscura</b>, mantenla <b>enfocada</b> dentro de la <b>guía</b> y sigue las instrucciones.</p>
                            }
                            <button type="button" onClick={(e) => {
                                this.setState({ isStart: false });
                                this.setState({ showIdOverlay: this.state.desktop ? true : false });
                                this.checkCamera();
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">{(this.state.desktop ? "CONTINUAR" : "SIGUIENTE")}</button>
                        </div>
                    </div>
                </Fragment> : ("")}


                <div className="module_container overflow_hddn desk_id_capture" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame">
                        <video id="video_wrt" playsInline></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradient_overlay"></div>
                    {(this.state.isHoloInstructions) ? <div><div className="module_title animated slideInDown">
                        <p>Vamos a <b>Calibrar</b> la cámara</p>
                    </div>
                        <div>
                            <img className="ine_mask_cel animated fadeIn delay-2s" src="/images/id_mask_capture_hollo.svg" alt="" />
                        </div> </div> : <div className="module_title animated slideInDown">
                        <p>Coloca el <b>{this.state.side === "front" ? "frente" : "reverso"}</b> de tu identificación</p>
                    </div>}

                    {(this.state.isHoloInstructions) ? ("") : (isMobile) ? <img className="ine_mask_cel animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/id_mask_capture_a.svg'} alt="" /> : <img className="ine_mask id_mask animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/id_mask.svg'} alt="" />}
                    <div className="module_icon_container">
                    </div>
                </div>
                {(this.state.isHoloInstructions ? ("") : <div className="alert_instruction animated fadeIn delay-1s alert_light">
                    <img height="24" src={process.env.PUBLIC_URL + 'images/evita_reflejos_warn.png'} alt="" />
                </div>)}
                {(this.state.showHoloAnimation ? <div className="alert_instruction animated fadeIn delay-1s alert_light">
                    <img src={process.env.PUBLIC_URL + 'images/hollo_animation.gif'} height="100" alt="" />
                </div> : (""))}

                {(this.state.isHoloInstructions) ? <div className="module_buttons animated fadeIn delay-3s"> <button type="button" onClick={() => this.initRecordHologram("front")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessingHolo || this.state.loading}>{this.state.proccessingHolo || this.state.loading ? "PROCESANDO ..." : "CAPTURAR Y CALIBRAR"}</button> <button type="button" onClick={() => this.cancelarCapturaIdentificacion()} className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button> </div> : ("")}

                {!this.state.isHoloInstructions && this.state.intentsFront <= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) ? (<div className="module_buttons animated fadeIn delay-3s">
                    {this.state.side === "front" ? <button type="button" onClick={() => this.initRecord("front")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR FRENTE")}</button> : <button type="button" onClick={() => this.initRecord("back")} className={["btn btn-raised btn-primary forcewidth100 main_bg_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR REVERSO")}</button>}
                    <button type="button" onClick={() => this.cancelarCapturaIdentificacion()} className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button>
                </div>
                ) : ("")}
                {((this.state.desktop && this.state.showIdOverlay && !this.state.isPassport) ? <div className="id_hold show_id_hold">
                    <div className="center_info_bx">
                        <img src="images/id_hold_icon.png" height="200" alt="" />
                        <p>Toma tu identificación solo con dos dedos <br /> sin obstruir tu foto u otra información<br />
                            <small>Sugerimos tomarla de la esquina superior izquierda</small>
                        </p>
                        <br />
                        <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => (this.setState({ showIdOverlay: false }))}>ENTENDIDO EMPEZAR</button>
                    </div>
                </div> : (""))}
                {((this.state.desktop && this.state.showIdOverlay && this.state.isPassport) ?
                    <div className="pass_hold show_id_hold">
                        <div className="center_info_bx">
                            <img src="images/pass_scan.svg" height="100" alt="" />
                            <p>Sólo necesitas escanear la sección baja de la identificación que contiene tus datos personales.<br />
                                <small>Procura no obstruir ningún dato o tu fotografía</small>
                            </p>
                            <br />
                            <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => (this.setState({ showIdOverlay: false }))}>ENTENDIDO EMPEZAR</button>
                        </div>
                    </div> : (""))}

                {((this.state.isHoloInstructions && !this.state.isStart) ?
                    <div className="hollo_gif_bx show_id_hold">
                        <div className="center_info_bx">
                            <img src="images/holo_gif_web.gif" height="150" alt="" />
                            <p>Ubica tu identificación al centro, presiona capturar, mueve tu dispositivo <u className="font-weight-bolder text-lg">lentamente</u> hacia la izquierda y hacia la derecha.<br />
                                <small>Vamos a calibrar la cámara de tu dispositivo</small>
                            </p>
                            <br />
                            <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => (this.setState({ isStart: true }))}>ENTENDIDO CALIBRAR</button>
                        </div>
                    </div> : (""))}

                {this.state.intentsFront >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) ? (<div className="err_finale  animated slideInUp">
                    <div className="center_checks"> {this.intentosBloqueo()}
                        <h5>Proceso no exitoso </h5>
                        <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuid).split("-")[0]}</b> </p>
                        <br />
                        {
                            (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") ?
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <p><b>Llámanos al:</b></p>
                                            <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                        </div>
                                        <div className="col">
                                            <p><b>Escríbenos a:</b></p>
                                            <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                        }
                        <div className="action_buttons noscroll_screen">
                            {
                                <Link to={"/" + this.state.apikey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                            }
                        </div>
                    </div>
                </div>) : ("")}

                {(this.state.proccessing) ? <LoaderOCR side={this.state.side} /> : ("")}
                {(this.state.proccessingHolo) ? <Loader /> : ("")}

                {((this.state.loading && !this.state.showHoloAnimation) ? <div className="sprite_stay_a animado"></div> : (""))}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>
                    ) : ("")
                }
                {(this.state.intentsData === 1) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animated slideInDown">No se pudo leer correctamente tus datos, por favor vuelve a intentar</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ intentsData: 2 })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.errorMsg) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animated slideInDown">Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorMsg: false })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animated slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                window.location.reload()
                                //this.props.history.push("/identificacion")
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        );
    }


}
CameraPhotoIOS = withOrientationChange(CameraPhotoIOS)
export default withRouter(CameraPhotoIOS)