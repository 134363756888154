import axios from 'axios';
import { obtenerValorConfig } from '../services/configStyle';

//const host = process.env.REACT_APP_URL
//const servicio = process.env.REACT_APP_SERVICE

const host = "https://api.dev.devdicio.net";
const servicio = "qa_";


const correoH = window.location.hostname;

const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    otorgantePK: `${host}/v1/sec_${servicio}params_pk`,
    ocr: `${host}/v1/sec_${servicio}ocr`,
    correo: `${host}/v1/sec_${servicio}mail_py`,
    pdftoken: `${host}/v1/sec_${servicio}token_numbers_pk`,
    score: `${host}/v1/sec_${servicio}query_score`,
    generarSMS: `${host}/v1/sec_${servicio}token_sms_ac`,
    validarSMS: `${host}/v1/sec_${servicio}verify_token_sms/verify`,
    otp: `${host}/v1/sec_${servicio}otp`,
    actualizarScore: `${host}/v1/sec_${servicio}score_update`,
    gps: `${host}/v1/sec_${servicio}address`,
    actualizarEstado: `${host}/v1/sec_${servicio}update_cycle`,
    getCP: `${host}/v1/sec_${servicio}postalcodes`,
    sendRenapo: `${host}/v1/sec_${servicio}valid_curp`,
    sendIne: `${host}/v1/sec_${servicio}valid_ine`,
    getDocuments: `${host}/v1/sec_${servicio}proccessdocuments`,
    sendComprobante: `${host}/v1/sec_${servicio}file`,
    sendProfile: `${host}/v1/sec_${servicio}datauser_update`,
    newVideoToken: `${host}/v1/sec_${servicio}video_token`
};

const encabezadosJson = {
    'Content-Type': 'application/json',
    'transaccion': localStorage.getItem("uuidTrx"),
    'cliente': localStorage.getItem("uuidUser"),
    'apikey': localStorage.getItem("apikeyOtorgante")
}

export function actualizarEstado(objeto) {
    var data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarEstado,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function obtenerParametrosOtorgante(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerParametrosOtorgantePK(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePK,
        headers: {

            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),

        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("ine", imagen, side + "-" + (new Date().getTime()) + ".png");
    data.append("side", side);
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    //console.log("enviando:"+side + "-" + (new Date().getTime()) + ".png");
    return axios({
        method: 'post',
        url: endpoints.ocr,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

//uuid, mailto, nombre, nacimiento, curp, otorgante, vigencia, coincidencia, liveness,textoConsentimiento, apiKey 
export function enviarCorreoAceptacion({
    uuid,
    mailto,
    nombre,
    nacimiento,
    curp,
    otorgante,
    vigencia,
    coincidencia,
    liveness,
    textoConsentimiento,
    apiKey,
    dataJSON
}) {
    var data = new FormData();
    //datos para imagenes 2
    data.append("uuidUser", uuid);
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    //datos personales 5
    data.append("mailto", mailto);
    data.append("name", nombre);
    data.append("fNac", nacimiento);
    data.append("curp", curp);
    data.append("otorgante", otorgante);
    data.append("apiOtorgante", apiKey);
    data.append("vigencia", vigencia);
    //datos biometricos 4
    data.append("coincidencia", coincidencia);
    data.append("liveness", liveness);
    data.append("textoConsentimiento", textoConsentimiento);
    data.append("dataJSON", dataJSON);
    return axios({
        method: 'post',
        url: endpoints.correo,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'mail-host': correoH,
            'Accept': 'application/json'
        },
        data: data
    });
}

export function obtenerTokenVideo(uuid) {
    console.log("endpoints", endpoints.newVideoToken);
    
    return axios({
        method: 'post',
        // url: endpoints.token,
        url:endpoints.newVideoToken,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        params: {
            uuid: uuid,
            service_call:'videotoken-embedding'
        }
    });
}

export function actualizarScore(uuid, calle, colonia, cp, estado, municipio, numeroExt, gps, empresa) {
    var data = JSON.stringify({
        "update": "true",
        "uuid": uuid,
        "otorgante_uuid": localStorage.getItem("uuidOtorgante"),
        "otorgante_name": empresa,
        "calle": calle,
        "colonia": colonia,
        "cp": cp,
        "estado": estado,
        "municipio": municipio,
        "numero": numeroExt,
        "gps": gps,
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: data
    });
}

export function obtenerTokenPDFVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.pdftoken,
        // url:endpoints.newVideoToken,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            uuid: uuid,
            service_call:'videotoken-pdf'
        }
    });
}

export function verificarTokenVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.verify,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function verificarTokenPDFVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.pdfverify,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function enviarImagen(uuid, file) {
    var data = new FormData();
    data.append("file", file, (new Date().getTime()) + "_frame.jpg");
    data.append("uuid", uuid);
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.otp,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function crearSMS(uuid, numero, area) {
    return axios({
        method: 'post',
        url: endpoints.generarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "number": numero,
                "areaCode": area
            }
        }
    });
}

export function validarSMSServicio(uuid, numero, area, codigo) {
    return axios({
        method: 'patch',
        url: endpoints.validarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "areaCode": area,
                "number": area + numero,
                "token": codigo
            }
        }
    });
}

export function obtenerScore(uuid, isDesktop) {
    return axios({
        method: 'get',
        url: endpoints.score + '/' + uuid,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        params: {
            isDesktop: isDesktop
        }
    });
}

export function obtenerEstadoEnlace(enlace) {
    return axios({
        method: 'get',
        url: enlace,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        }
    });
}

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const getImageDimensions = file => new Promise((resolved, rejected) => {
    var i = new Image();
    i.onload = function () {
        resolved({
            w: i.width,
            h: i.height
        });
    };
    i.src = file;
});

export function obtenerMaps(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.gps,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

export function obtenerCP(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.getCP,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

/** RENAPO */

export function enviarRenapo(curp) {
    return axios({
        method: 'POST',
        url: endpoints.sendRenapo,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante"),
        },
        data: {
            "uuid": localStorage.getItem("uuidUser"),
            "turn": -1,
            "curp": curp
        }
    })
}

/** INE */

export function enviarIne(cic, claveElector, numeroEmision) {
    let data = {};
    if (cic.length === 13) {
        data = {
            "ocr": cic,
            "claveElector": claveElector,
            "numeroEmisionCredencial": numeroEmision,
        }
    } else {
        data = {
            "cic": cic
        }
    }
    return axios({
        method: 'POST',
        url: endpoints.sendIne,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'Otorgante': localStorage.getItem("uuidUser")
        },
        data: {
            "uuid": localStorage.getItem("uuidUser"),
            "turn": -1,
            "data": data,
            "fingerprint": {
                "indexLeft": null,
                "indexRight": ""
            }
        }
    })
}

/* DOCUMENTOS */
export function getDocuments() {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            uuidUser: localStorage.getItem("uuidUser")
        }
    })
}

/* Enviar perfil */
export function enviarPerfil(arrayProfile) {
    return axios({
        method: 'POST',
        url: endpoints.sendProfile,
        headers: {
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            'transaccion' : localStorage.getItem("uuidTrx"),
            'cliente_id': localStorage.getItem("uuidUser"),
            'perfil': arrayProfile
        }
    })
}

// Enviar video
export function enviarVideoRostro(facefile, Acreated, Aexpire, isIOS ) {
    console.log("isIOS:", isIOS);
    var data = new FormData();

    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", "android");
    
    data.append("service_call", "videotoken-embedding");

    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);
    console.log("llene el form:", VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        url: endpoints.newVideoToken, 
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}
