import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { actualizarEstado, enviarRenapo, enviarIne } from '../services/api.js';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esNumLetras, esSoloLetras, esNumeros } from '../services/data'
import Info from '../components/encuentra_info';


let ResultadosIdentificacion = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [nombre, setNombre] = useState('')
    const [focusNombre, setFocusNombre] = useState(false)
    const [nacimiento, setNacimiento] = useState('')
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [curp, setCurp] = useState('')
    const [focusCurp, setFocusCurp] = useState(false)
    const [vigencia, setVigencia] = useState('')
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false)
    const [esVigente, setEsVigente] = useState(true)
    const [datosCorrectos, setdatosCorrectos] = useState(true)
    const [esCLaboral, setCLaboral] = useState(true)
    const [noOCR, setNoOCR] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [esPermanente, setEsPermanente] = useState(false)
    const [esMigratoria, setEsMigratoria] = useState(false)
    /*INE*/
    const [esIne, setEsIne] = useState(false)

    const [clave, setclave] = useState('')
    const [focusClave, setfocusClave] = useState(false)

    const [registro, setRegistro] = useState('')
    const [focusRegistro, setfocusRegistro] = useState(false)

    const [emision, setemision] = useState('')
    const [focusEmision, setfocusEmision] = useState(false)

    const [CIC, setCIC] = useState('')
    const [focusCIC, setfocusCIC] = useState(false)

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        if (hayExcepcion()) {
            setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            if (ocrFront.identificacionElectoral) {
                setEsIne(true);
                if (ocrFront.claveElector)
                    setclave((ocrFront.claveElector).trim())
                if (ocrFront.anioRegistro)
                    setRegistro((ocrFront.anioRegistro).trim())
                if (ocrFront.numeroRegistro)
                    setemision((ocrFront.numeroRegistro).trim())
            }
            if (ocrFront.identificacionPasaporte)
                setEsPassport(true)
            setDataOCRFront(ocrFront)
            setNombre(ocrFront.nombreCompleto)
            //setNombre(ocrFront.nombres + " " + ocrFront.apellidoPaterno + " " + ocrFront.apellidoMaterno)
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {

                    let añoActualInt = new Date().getFullYear().toString().substr(-2);
                    let añoFechaOcr = ocrFront.fechaNacimiento.substr(-2);
                    let añoFechaOcrInt = ocrFront.fechaNacimiento.substr(-2);
                    let fechaSinAño = ocrFront.fechaNacimiento.substr(0, 6);
                    let fechaOcr = '';
                    if (añoFechaOcrInt >= añoActualInt) {
                        console.log("entre 19");
                        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
                        console.log("fechaOcr", fechaOcr);
                        formatoFechaOcr(fechaOcr);
                    } else {
                        let mayoriaDeEdad = añoActualInt - 18;
                        if (añoFechaOcrInt <= mayoriaDeEdad) {
                            console.log("entre 20");
                            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                            formatoFechaOcr(fechaOcr);
                        } else {
                            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                        }

                    }
                } else {
                    //console.log("entre fecha completa");
                    formatoFechaOcr(ocrFront.fechaNacimiento);
                }
            }
            if (hayExcepcion()) {
                if (!hayLetras(ocrFront.dni)) {
                    setCurp(ocrFront.dni)
                } else {
                    setCurp("")
                }
            } else {
                if (ocrFront.curp)
                    if (ocrFront.curp.length > 14)
                        setCurp((ocrFront.curp).trim())
            }
            let vigenciaC = ''
            if (ocrFront.vigencia != null) {
                if (ocrFront.identificacionPasaporte) {
                    if (ocrFront.identificacionPasaporte || hayExcepcion()) {
                        //vigenciaC = ocrFront.vigencia
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                    } else {
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                    }
                } else if (ocrFront.identificacionMigratoria) {
                    setEsMigratoria(true);
                    if (ocrFront.vigencia === "PERMANENTE") {
                        setEsPermanente(true);
                        vigenciaC = ocrFront.vigencia;
                    } else {
                        if (hayExcepcion()) {
                            //console.log("hayExcepcion");
                            if (ocrFront.vigencia.includes(' '))
                                vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                            else
                                vigenciaC = ocrFront.vigencia
                        } else {
                            vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                        }
                    }
                } else {
                    if (hayExcepcion()) {
                        //console.log("hayExcepcion");
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                    } else {
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                    }
                }
            }
            setVigencia(vigenciaC);
            //console.log("enfrente", vigenciaC);
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }
        //console.log("esCLaboral",esCLaboral);

        let dataOCRBackLS = localStorage.getItem("ocrBack")

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS))
            const ocrBack = JSON.parse(dataOCRBackLS)
            let vigenciaC = '';
            //console.log("datos:", dataOCRBack, dataOCRFront, inputActive);
            if (ocrBack.ineVigencia != null) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    setVigencia("20" + vigenciaC.substr(-2))
                }
            } else {
                if (ocrFront.vigencia != null) {
                    //console.log("v: " + vigenciaC)
                    if (vigenciaC) {
                        setVigencia("20" + vigenciaC.substr(-2))
                        //console.log("fecha:" + vigenciaC);
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vi = ocrFront.vigencia
                        setVigencia(vi)
                    }
                } else {
                    if (hayExcepcion()) {
                        vigenciaC = ""
                    } else {
                        if (ocrFront.vigencia) {
                            vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                        }
                    }
                    setVigencia(vigenciaC)
                }
            }
            if (ocrBack.cicMRZ)
                setCIC((ocrBack.cicMRZ).trim())
        }
        status("datos_personales_ocr_page", "Datos personales OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    const hayLetras = (input) => {
        let result = true
        var expreg = /[a-z]/gi;
        //var result = input.match(expreg);
        if (!input.match(expreg))
            result = false
        return result
    }

    const actualizarDatos = (e) => {
        e.preventDefault();
        let continuar = true;
        let frase = "Revisa el formato de fecha dd/mm/aaaa"
        let formatosP = ['DD/MM/YYYY']
        if (noOCR) {
            if (nombre.length < 10 || !esSoloLetras(nombre)) {
                continuar = false; frase = "Nombre no válido"
                document.getElementById("ht-nombre").innerHTML = frase
            } else {
                document.getElementById("ht-nombre").innerHTML = ""
            }
            let nInput = document.getElementById("inputFechaNacimiento").value;
            setNacimiento(nInput)
            if (moment(nInput, formatosP, true).isValid()) {
                document.getElementById("ht-nacimiento").innerHTML = ""
            } else {
                continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                document.getElementById("ht-nacimiento").innerHTML = frase
            }
            if (esPassport || hayExcepcion()) {
                let vigenciaInput = document.getElementById("inputFechaVigencia").value;
                setVigencia(vigenciaInput)
                if (moment(vigenciaInput, formatosP, true).isValid()) {
                    document.getElementById("ht-vigencia").innerHTML = ""
                } else {
                    continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                    document.getElementById("ht-vigencia").innerHTML = frase
                }
            } else {
                if ((vigencia.length != 4) && (esPermanente == false)) {
                    continuar = false; frase = "Revisa que el año sea 4 digitos"
                    document.getElementById("ht-vigencia").innerHTML = frase
                } else if (!esNumeros(vigencia) && (esPermanente == false)) {
                    continuar = false; frase = "Formato de vigencia no válido"
                    document.getElementById("ht-vigencia").innerHTML = frase
                } else {
                    setVigencia(vigencia); document.getElementById("ht-vigencia").innerHTML = ""
                }
            }
            if (!hayExcepcion()) {
                if (curp.length < 16 || !esNumLetras(curp) || curp.length > 18) {
                    continuar = false; frase = "Formato de Curp no válido"
                    document.getElementById("ht-curp").innerHTML = frase
                } else {
                    document.getElementById("ht-curp").innerHTML = ""
                }
            } else {
                if (curp.length < 3 || !esNumLetras(curp)) {
                    continuar = false; frase = "Formato de DNI no válido"
                    document.getElementById("ht-curp").innerHTML = frase
                } else {
                    document.getElementById("ht-curp").innerHTML = ""
                }
            }

        } else {
            let nInput = document.getElementById("inputFechaNacimiento").value;
            setNacimiento(nInput)
            if (moment(nInput, formatosP, true).isValid()) {
                document.getElementById("ht-nacimiento").innerHTML = ""
            } else {
                continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                document.getElementById("ht-nacimiento").innerHTML = frase
            }
            if (esPassport || hayExcepcion()) {
                let vigenciaInput = document.getElementById("inputFechaVigencia").value;
                setVigencia(vigenciaInput)
                if (moment(vigenciaInput, formatosP, true).isValid()) {
                    document.getElementById("ht-vigencia").innerHTML = ""
                } else {
                    continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                    document.getElementById("ht-vigencia").innerHTML = frase
                }
            } else {
                if ((vigencia.length != 4) && (esPermanente == false)) {
                    continuar = false; frase = "Revisa que el año sea 4 digitos"
                    document.getElementById("ht-vigencia").innerHTML = frase
                } else {
                    setVigencia(vigencia); document.getElementById("ht-vigencia").innerHTML = ""
                }
            }
            //continuar = true;
        }

        if (!esNumLetras(curp) || curp.length < 16 || curp.length > 18) {
            continuar = false; frase = "Formato de Curp no válido"
            document.getElementById("ht-curp").innerHTML = frase
        } else {
            document.getElementById("ht-curp").innerHTML = ""
        }
        if (continuar) {
            // if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "true") === "true") {
            //     enviarRenapo(curp).then(response => {
            //         //console.log("RENAPO");
            //     }).catch(error => {
            //         //console.log("RENAPO: " + error);
            //     });
            // }

            // if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) {
            //     enviarIne(CIC, clave, emision).then(response => {
            //         //console.log("INE");
            //     }).catch(error => {
            //         //console.log("INE: " + error);
            //     });
            // }

            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombre", value: nombre });
            infoFormulario.push({ description: "nombre", value: nombre })
            let nInput = document.getElementById("inputFechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: nInput });
            infoFormulario.push({ description: "nacimiento", value: nInput })

            if (hayExcepcion()) {
                agregarInfo(dataUser, { description: "dni", value: curp });
                infoFormulario.push({ description: "dni", value: curp })
            } else {
                agregarInfo(dataUser, { description: "curp", value: curp });
                infoFormulario.push({ description: "curp", value: curp })
            }
            let fechaActual = vigencia
            if (esPassport || hayExcepcion()) {
                let vinput = document.getElementById("inputFechaVigencia").value
                infoFormulario.push({ description: "vigencia", value: vinput })
                var arrayV = vigencia.split("/");
                fechaActual = arrayV[1]+"/"+arrayV[0]+"/"+arrayV[2];
                agregarInfo(dataUser, { description: "vigencia", value: vinput });

            } else {
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + vigencia.substr(-2) })
                agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + vigencia.substr(-2) });
                fechaActual = vigencia + "/12/31";
            }
            console.log("fechaActual",fechaActual);
            let fechaV = new Date(fechaActual);
            console.log("fechaV",fechaV);
            if (fechaV >= Date.now()) {
                console.log("Vigencia válida");
                setTimeout(() => {
                    sendData(infoFormulario);
                    if (hayExcepcion()) {
                        history.push('/clausula');
                    } else {
                        history.push('/identificacion_edit')
                    }
                }, 300);
            }else{
                console.log("Vigencia invalida");
                setEsVigente(false)
            }
        } else {
            //console.log(frase);
        }

    }

    useEffect(() => {
        if (noOCR) {
            setdatosCorrectos(true)
        } else {
            if (curp && nombre) {
                if (hayExcepcion()) {
                    if (curp.length < 3 || nombre.length < 10) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                } else {
                    if (curp.length < 16 || curp.length > 18 || nombre.length < 10) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                }
            } else {
                setdatosCorrectos(false)
            }
        }
        //console.log("DC:",datosCorrectos);
    }, [curp, nombre])

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    const formatoFechaOcr = (fecha) => {
        let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
        let nacimientoC = fecha
        if (fecha.includes(' '))
            nacimientoC = fecha.replaceAll(' ', '/')
        if (moment(nacimientoC, formatosP, true).isValid())
            setNacimiento(nacimientoC)
        else {
            setNacimiento("")
        }
    }

    return (
        <div className="main_gradient">
            <Header ruta="resultados_identificacion" />
            <div className="main_text_container">
                <h1 className="animated">Captura procesada, por favor verifica tu información</h1>
                <p className="animated">Es muy importante <b>verificar</b> que la información capturada <b>sea fiel</b> a la información de tu <b>identificación</b>, por favor asegura que tus datos son <b>correctos</b> o presiona <b>capturar nuevamente</b>.</p>
            </div>
            <form>
                <div className="ocr_data_display animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || nombre !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputNombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="inputNombreCompleto" defaultValue={nombre} onChange={e => setNombre(e.target.value)} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (nombre === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || nacimiento !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputFechaNacimiento" className="bmd-label-floating">Fecha de nacimiento:</label>
                        <NumberFormat format="##/##/####" className="form-control" id="inputFechaNacimiento" value={nacimiento} onChange={e => setNacimiento(e.target.value)} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (nacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                    {(true) ?
                        <div>
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || vigencia !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputFechaVigencia" className="bmd-label-floating">{((esPassport || hayExcepcion()) ? "Vencimiento identificación:" : "Vencimiento identificación: (año)")}</label>
                                {
                                    (esPassport || hayExcepcion()) ?
                                        <NumberFormat format="##/##/####" className="form-control" id="inputFechaVigencia" value={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                            setFocusVigencia(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (vigencia === "") {
                                                setFocusVigencia(false)
                                            }
                                            setInputActive(false)
                                        }} />
                                        : (esPermanente) ?
                                            <input type="text" className="form-control" id="inputFechaVigencia" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                setFocusVigencia(true)
                                                setInputActive(true)
                                            }} onBlur={e => {
                                                if (vigencia === "") {
                                                    setFocusVigencia(false)
                                                }
                                                setInputActive(false)
                                            }} />
                                            :
                                            <input type="text" className="form-control" id="inputFechaVigencia" maxLength="4" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                setFocusVigencia(true)
                                                setInputActive(true)
                                            }} onBlur={e => {
                                                if (vigencia === "") {
                                                    setFocusVigencia(false)
                                                }
                                                setInputActive(false)
                                            }} />
                                }

                                <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusCurp || curp !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputCurp" className="bmd-label-floating">{(hayExcepcion()) ? "DNI" : "CURP"}</label>
                                {
                                    (esMigratoria) ?
                                        <input type="text" className="form-control" id="inputCurp" defaultValue={curp} onChange={e => setCurp(e.target.value)} onFocus={e => {
                                            setFocusCurp(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (curp === "") {
                                                setFocusCurp(false)
                                            }
                                            setInputActive(false)
                                        }} disabled={curp ? true : false} maxLength="19" />
                                        :
                                        <input type="text" className="form-control" id="inputCurp" defaultValue={curp} onChange={e => setCurp(e.target.value)} onFocus={e => {
                                            setFocusCurp(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (curp === "") {
                                                setFocusCurp(false)
                                            }
                                            setInputActive(false)
                                        }} />
                                }

                                <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={["form-group", "bmd-form-group", ((focusClave || clave !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputclaveCompleto" className="bmd-label-floating">Clave de elector:<small>(Dato frontal)</small></label>
                                <input type="text" className="form-control" id="inputclaveCompleto" maxLength="20" defaultValue={clave} onChange={e => setclave(e.target.value)} onFocus={e => {
                                    setfocusClave(true)
                                }} onBlur={e => {
                                    if (clave === "") {
                                        setfocusClave(false)
                                    }
                                }} />
                                <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className={["form-group", "bmd-form-group", ((focusRegistro || registro !== "") ? "is-focused" : "")].join(" ")}>
                                        <label htmlFor="inputFechaRegistro" className="bmd-label-floating">Año de registro:<small>(Dato frontal)</small></label>
                                        <input type="text" className="form-control" id="inputFechaRegistro" maxLength="4" value={registro} onChange={e => setRegistro(e.target.value)} onFocus={e => {
                                            setfocusRegistro(true)
                                        }
                                        } onBlur={e => {
                                            if (registro === "") {
                                                setfocusRegistro(false)
                                            }
                                        }} />
                                        <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right"></span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className={["form-group", "bmd-form-group", ((focusEmision || emision !== "") ? "is-focused" : "")].join(" ")}>
                                        <label htmlFor="inputFechaemision" className="bmd-label-floating">Número de emisión:<small>(Dato frontal)</small></label>
                                        <input type="text" className="form-control" id="inputFechaemision" value={emision} maxLength="2" onChange={e => setemision(e.target.value)} onFocus={e => {
                                            setfocusEmision(true)
                                        }
                                        } onBlur={e => {
                                            if (emision === "") {
                                                setfocusEmision(false)
                                            }
                                        }} />
                                        <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right"></span>
                                    </div>
                                </div>
                            </div>

                            <div className={["form-group", "bmd-form-group", ((focusCIC || CIC !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputCIC" className="bmd-label-floating">CIC/OCR:<small>(Dato reverso)</small></label>
                                <input type="text" className="form-control" id="inputCIC" maxLength="13" defaultValue={CIC} onChange={e => setCIC(e.target.value)} onFocus={e => {
                                    setfocusCIC(true)
                                }} onBlur={e => {
                                    if (CIC === "") {
                                        setfocusCIC(false)
                                    }
                                }} />
                                <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>

                            <div className={["form-group", "bmd-form-group", ((focusCurp || curp !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputCurp" className="bmd-label-floating">{(hayExcepcion()) ? "DNI" : "CURP"}</label>
                                <input type="text" className="form-control uppercase" id="inputCurp" defaultValue={curp} maxLength="19" onChange={e => setCurp(e.target.value.toLocaleUpperCase())} onFocus={e => {
                                    setFocusCurp(true)
                                }} onBlur={e => {
                                    if (curp === "") {
                                        setFocusCurp(false)
                                    }
                                }} />
                                <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div className={["form-group", "bmd-form-group", ((focusVigencia || vigencia !== "") ? "is-focused" : "")].join(" ")}>
                                        <label htmlFor="inputFechaVigencia" className="bmd-label-floating">{((esPassport || hayExcepcion()) ? "Vencimiento identificación:" : "Vencimiento identificación:")}</label>
                                        {
                                            (esPassport || hayExcepcion()) ?
                                                (vigencia !== "PERMANENTE") ?
                                                    <NumberFormat format="##/##/####" className="form-control" id="inputFechaVigencia" value={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                        setFocusVigencia(true)
                                                    }} onBlur={e => {
                                                        if (vigencia === "") {
                                                            setFocusVigencia(false)
                                                        }
                                                    }} />
                                                    :
                                                    <input type="text" className="form-control" id="inputFechaVigencia" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                        setFocusVigencia(true)
                                                    }} onBlur={e => {
                                                        if (vigencia === "") {
                                                            setFocusVigencia(false)
                                                        }
                                                    }} />
                                                :
                                                <input type="text" className="form-control" id="inputFechaVigencia" maxLength="4" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                    setFocusVigencia(true)
                                                }} onBlur={e => {
                                                    if (vigencia === "") {
                                                        setFocusVigencia(false)
                                                    }
                                                }} />
                                        }

                                        <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right"></span>
                                    </div>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <Info />
                        </div>
                    }
                </div>
                <div className="action_buttons animated">
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color">CAPTURAR NUEVAMENTE</Link>
                    <button type="button" disabled={(esMigratoria) ? (!nombre || !nacimiento || !vigencia || !curp) : (!nombre || !nacimiento || !vigencia || (curp.length < 16))} className={["btn btn-raised btn-primary forcewidth100 main_bg_color", ((!datosCorrectos) ? "disabled" : "")].join(" ")} onClick={e => actualizarDatos(e)}>MIS DATOS SON CORRECTOS</button>
                </div>
            </form>
            {(!esVigente) ? (<div className="err_finale  animated slideInUp">
                <div className="center_checks">
                    <h5>Identificación no valida </h5>
                    <p>La <b>vigencia</b> de tu identificación <b>{vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                    </p>
                    <br />
                    <div className="action_buttons noscroll_screen">
                        <Link to={{
                            pathname: "/" + apiKey
                        }} className="btn btn-secondary forcewidth100">TERMINAR</Link>
                        <Link to={{
                            pathname: "/identificacion",
                            state: { passport: esPassport }
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                    </div>
                </div>
            </div>) : ("")}
            <Footer />
        </div>
    )
}

export default ResultadosIdentificacion