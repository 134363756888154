/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import Loader from "../components/loader";
import Typist from 'react-typist';
import { verificarTokenVideo, enviarImagen, actualizarEstado, enviarVideoRostro } from '../services/api';
import { isIOS, isMobileSafari, isFirefox, isMobile, isIPad13, withOrientationChange } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { status, statusError, statusData, statusReintento } from '../services/data'
import RecordRTC from 'recordrtc';

const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "No identificamos tu rostro, por favor centra tu rostro en el área marcada."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjDistancia = "Por favor asegúrate de centrar tu rostro en el área marcada y captura nuevamente."
const msjAudio = "No logramos capturar audio en tu captura, asegúrate que tu micrófono funciona correctamente e intenta nuevamente."
const msjError = "Lo sentimos no hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente"
const msjMov = "No logramos validar tus movimientos, por favor sigue las instrucciones e intenta nuevamente"

class CameraVideoTokenIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        token: null,
        isRecording: false,
        isComplete: false,
        isStart: true,
        uuid: null,
        errorToken: false,
        dataUser: [],
        dataOtorgante: [],
        codes: [],
        face: {},
        errorMessage: "",
        intents: 0,
        apikey: "",
        hasResponse: false,
        conteo: false,
        codec: "video/webm;codecs=vp8",
        errorF: "",
        errorVideo: false,
        streamStarted: false,
        pathname: ""
    }

    componentDidMount() {
        if (isFirefox && !isMobile) {
            this.state.codec = "video/webm;codecs=vp9"
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }

        let codesLS = localStorage.getItem("codes")
        if (codesLS !== null) {
            this.setState({ codes: JSON.parse(codesLS) });
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }

        this.state.pathname = window.location.pathname;

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }

        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intents = 5;
                //console.log("intentsFront: " + this.state.intentsFront);
            }
        }
        //console.log("bloque: " + bloqueo);

        this.localStream = window.navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        });

        status("captura_video_token_page", "Captura Video Token")

        this.canvas = document.querySelector('canvas');
        this.width = 320;
        this.height = 0;
        this.video = document.querySelector('video');
        this.elemento = document.getElementById("cuadro");
        this.cuenta = document.getElementById("numeros");
        this.titulo = document.getElementById("head_shop");
        let component = this;
        this.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    //console.log("disponibles");
                    component.setState({ streamStarted: true, errorVideo: false })
                    component.recorder = new RecordRTC(mediaStream, {  // Indicamos que inicie la grabación en la linea: 367
                        type: 'video',
                        mimeType : 'video/webm',
                        //bitsPerSecond : 128000      // Graba con una menor calidad
                    });
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            this.setState({ errorVideo: true })
        })
        /*
        window.onbeforeunload = function(e) {
            if (window.opener) {
                window.opener.postMessage(JSON.stringify({
                step: "incompleted",
                result: "FAIL",
                type: "PROCESS",
                error_text: "Procesos interrumpido por usuario",
              }), localStorage.getItem("externalReference"))
            }
            return 'Procesos interrumpido por usuario';
          };
          */
    }

    takepicture = () => {
        //console.log("en funcion para tomar foto");
        setTimeout(() => {
            //console.log("tomando foto");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                enviarImagen(that.state.uuid, blob)
                    .then((response) => {
                        if (response.status === 200) {
                            //console.log(response.data.payload.codes);
                            let listCodes = response.data.payload.codes;//.map((elemento, indice) => { return { used: false, token: elemento, expire: new Date().getTime(), created: new Date().getTime() } });
                            that.setState({ codes: listCodes });
                            localStorage.setItem("codes", JSON.stringify(listCodes));

                            setTimeout(async () => {
                                that.cuenta.innerHTML = "";
                                that.cuenta.className = "token_txt animated";
                                that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                that.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true })
                                //.....
                                let tokenLS = listCodes.pop();
                                //console.log(tokenLS);
                                that.setState({ token: tokenLS });
                                localStorage.setItem("codes", JSON.stringify(listCodes));
                                const token = tokenLS.code;
                                const connectionClient = new ConnectionClient();
                                let peerConnection1 = null;

                                const localVideo = document.getElementById('video_wrt_environment');
                                localVideo.autoplay = true;
                                localVideo.muted = true;

                                async function beforeAnswer(peerConnection) {
                                    that.localStream.then(function (mediaStream) {
                                        mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                                    })
                                    peerConnection1 = peerConnection;
                                    let dataChannel = null;
                                    let interval = null;
                                    let timeoutt = null;
                                    function onMessage({ data }) {
                                        const responseWRT = JSON.parse(data);
                                        //console.log("responseWRT", responseWRT);
                                        if (responseWRT.status === 200) {
                                            if (responseWRT.res.payload.match) {
                                                that.setState({ loading: false })
                                                that.setLocalStorageResultFace(responseWRT.res.payload)
                                                that.sendData(responseWRT.res.payload.match)
                                                setTimeout(() => {
                                                    that.props.history.push("/consulta_historial")
                                                }, 300);
                                            } else {
                                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null })
                                                if (that.state.intents === 3)
                                                    that.statusF();
                                                else
                                                    that.statusR();
                                            }
                                            that.setState({ loading: false, intents: 0, hasResponse: true })
                                            peerConnection.close()
                                        } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                            let mensaje = responseWRT.res.message_client
                                            let eMessage = "Asegúrate de colocar tu rostro dentro del área y pronunciar correctamente."
                                            if (mensaje === "No se encontro rostro") {
                                                eMessage = msjRostro
                                            } else if (mensaje === "Se detecto cubrebocas") {
                                                eMessage = msjCubrebocas
                                            } else if (mensaje === "Condiciones de luz no adecuadas") {
                                                eMessage = msjLuz
                                            } else if (mensaje === "Imagen borrosa") {
                                                eMessage = msjBorrosa
                                            } else if (mensaje === "Se detectaron multiples caras") {
                                                eMessage = msjMultiple
                                            } else if (mensaje === "No logramos entender la frase, intente nuevamente") {
                                                eMessage = msjFrase
                                            } else if (mensaje === "Falta de luz") {
                                                eMessage = msjObscuridad
                                            } else if (mensaje === "Posicion de cara incorrecta") {
                                                eMessage = msjDistancia
                                            } else if (mensaje === "Error frase") {
                                                eMessage = msjFrase2
                                            } else if (mensaje === "No hay movimiento") {
                                                eMessage = msjMov
                                            }
                                            that.setState({ errorMessage: eMessage, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                            if (that.state.intents === 3)
                                                that.statusF();
                                            else
                                                that.statusR();
                                            peerConnection.close()
                                        } else if (responseWRT.status === 404) {
                                            that.setState({ errorMessage: msjFrase2, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                            if (that.state.intents === 3)
                                                that.statusF();
                                            else
                                                that.statusR();

                                            if (responseWRT.res.message_client === "Spoof detected") {
                                                that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) });
                                            }
                                            peerConnection.close()
                                        } else {
                                            that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                            if (that.state.intents === 3)
                                                that.statusF();
                                            else
                                                that.statusR();
                                            peerConnection.close()

                                        }
                                        that.titulo.innerHTML = "<p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>";
                                        that.cuenta.className = "cnt_dwn animated";
                                        localVideo.classList.remove("blur_video")
                                    }
                                    let intentos = 0
                                    function onDataChannel({ channel }) {
                                        if (channel.label !== 'ping-pong') {
                                            return;
                                        }
                                        dataChannel = channel;
                                        dataChannel.addEventListener('message', onMessage);
                                        interval = setInterval(() => {
                                            intentos++;
                                            if (intentos === 1) {
                                                //titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1);
                                            }
                                            if (intentos === 2) {
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                                            }
                                            if (intentos === 3) {
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                            }
                                            if (intentos === 4) {
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                            }
                                            if (intentos === 8) {
                                                that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });
                                                dataChannel.send("finish_record");
                                                localVideo.classList.add("blur_video")
                                                that.setState({ loading: false, hasResponse: false })
                                                that.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                                that.cuenta.innerHTML = "";
                                                that.cuenta.className = "";
                                            }
                                        }, 1000);
                                        timeoutt = setTimeout(() => {
                                            let contenedor = (window.location.hostname).includes("proddicio") ? "dco-bucket-prod" : (window.location.hostname).includes("dev.devdicio") ? "dco-bucket-qa" : "dco-bucket-dev";
                                            dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: contenedor, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service: 'videotoken-embedding' }));
                                        }, 500);
                                    }
                                    peerConnection.addEventListener('datachannel', onDataChannel);
                                    const { close } = peerConnection;
                                    peerConnection.close = function () {
                                        if (dataChannel) {
                                            dataChannel.removeEventListener('message', onMessage);
                                        }
                                        if (interval) {
                                            clearInterval(interval);
                                        }
                                        if (timeoutt) {
                                            clearTimeout(timeoutt)
                                        }
                                        return close.apply(this, arguments);
                                    };
                                }

                                peerConnection1 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                                window.peerConnection1 = peerConnection1;
                            }, 1000)


                        }
                    })
                    .catch((error) => {
                        let e = error.toString()
                        //console.log("e:", e); //Recuerda no usar cubreboca, lentes y evita colocarte de espaldas a una fuente de luz
                        if (e.includes("500")) {
                            that.setState({ errorMessage: "Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                        } else {
                            that.setState({ errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                        }
                        if (that.state.intents === 3)
                            that.statusF();
                        else
                            that.statusR();
                    }
                    )
            }, 'image/jpeg', 0.9);
        }, 3000);
    }

    takePhotoAndroid = () => {
        //console.log("Entraste a takePhotoAndroid");
        setTimeout(() => {
            // Mientras está el loader toma una foto para enviar al OTP
            let contextCanvas = this.canvas.getContext('2d');
            // this almacena los datos obtenidos del video mientras ejecuta otras funciones
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;      // Lo obtenido de this se almacena en la variable that para después usarla
            this.canvas.toBlob((blob) => {      // this envia el blop para crear el archivo
                // blob invoca a la funcion enviarImagen espera una promesa
                enviarImagen(that.state.uuid,blob)  // Crea un formData con los datos de la constante that
                // Cuando la respuesta es correecta entra a hacer otra función 
                .then((response) => {
                    //console.log("Esta haciendo la petición, si es iggual a 200 entra al siguiente if");
                    // Si la respuesta es 200 ejecuta
                    if (response.status === 200) {
                        //console.log("el estatus es:", response.status, "Entra el siguiente log que muestra la data de code");
                        // Guarda en la variable listCodes la respuesta que esta en codes
                        let listCodes = response.data.payload.codes;
                        //console.log("La data de codes es: ", response.data.payload.codes);  // revisamos que contiene codes
                        that.setState({ codes: listCodes });    // en una nueva varriable llamada codes guardamos lo que esta en listCodes
                        localStorage.setItem("codes", JSON.stringify(listCodes));    // convertimos lo que esta en codes para convertirlo en un archivo json y poder guardarlo en el local storage
                        //console.log("Entró a: listCodes");
                        //.....
                        let tokenLS = listCodes.pop();  // Creamos una nueva variable y le agregamos el "listCode" del ultimo arrreglo (pop: significa que va a agarrrar el ultimo arreglo)
                        //console.log("Códigos", listCodes);
                        that.setState({ token: tokenLS });
                        localStorage.setItem("codes", JSON.stringify(listCodes));


                        const token = tokenLS.code;     // Tomamos la variable TokenLs y obtenemos el code que viende dentro del objeto deel ultmio arreglo y lo guardamos en una nueva variable
                        
                        const Acreated = tokenLS.created;
                        //console.log("tokenLS.created", tokenLS.created);
                        const Aexpire = tokenLS.expire;
                        //console.log("tokenLS.expire", tokenLS.expire);

                        const localVideo = document.getElementById('video_wrt_environment');    // obtenemos la etiqueta con el id marcado y lo guardamos en una variable
                                localVideo.autoplay = true;         // al local video le indicamos que puede grabar
                                localVideo.muted = true;            // al local video le indicamos que esté en silencio

                        setTimeout(async () => {                    // Creamos una función asincrona
                                            // this.cuenta es un elemento que obtiene la etiiqueta con el id números
                            that.cuenta.innerHTML = "";             // that ahora es nuestro contexto y le indica que
                            that.cuenta.className = "token_txt animated";   // A ese elemento se le agrega la clase marcada
                            that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";    // Creamos un tittulo en el hmtl que muestra este texto
                            that.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true });      // banderas para inicializar
                            //console.log("Estamos deontrro del constrructor de numeros aleatorios");

                            that.recorder.startRecording();     // Indicamos que empiece a grabar, declaramos la funcion en la linea: 118
                            
                            let intentos = 0;
                            let interval = setInterval(() => {  // inicia la función  
                                intentos++;                     // declaramos que a cada intento empice en 0 y se sume de uno en uno
                                if (intentos === 1) {           // inicio del primer ciclo
                                    that.cuenta.className = "token_nmbr animated fadeIn";   // a la tag que invoca cuenta se le agregan las clases marcadas
                                    that.cuenta.innerHTML = token.substring(0, 1);          // lee el numero que esta en el estado 0 y el que esta antes del eestado 1 de la lista code que esta almacenada en la variablee token
                                    //console.log("Entró a ciclo 1: ", token);
                                }
                                if (intentos === 2) {           // inicio del segundo ciclo
                                    that.cuenta.className = "token_nmbr animated fadeIn";   // a la tag que invoca cuenta se le agregan las clases marcadas
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);    // Indica que agregue el valor del ciclo anterior y ahora agregue el numero que esta en el estado 1 y el que esta antes del estado 2 de la lista code que esta almacenada en la variablee token
                                    //console.log("Entró a ciclo 2: ", token);
                                }
                                if (intentos === 3) {           // inicio del tercer ciclo
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                    //console.log("Entró a ciclo 3: ", token);
                                }
                                if (intentos === 4) {           // inicio del cuarto ciclo
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                    //console.log("Entró a ciclo 4: ", token);
                                }
                                if (intentos === 7) {           // deja pasar el ciclo 5 y 6 hasta que llega al séptimo ciclo
                                    that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });     // deeclara las banderas si son todas correectas
                                    
                                    localVideo.classList.add("blur_video")  // una vez terminados los ciclos, le manda la clase (borrosa) al video 
                                    that.setState({ loading: false, hasResponse: false })   
                                    that.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";   // Cambia el textos
                                    that.cuenta.innerHTML = "";     // como ya no hay numeros los cambiamos a vacio
                                    that.cuenta.className = "";     // Tambien le quitamos la clase
                                    that.recorder.stopRecording(function () {   // indicamos que termine de grabar el video
                                        console.log("Ha terminado la grabación");
                                    });
                                }
                                if(intentos === 8){     // cuando llega al ciclo 8 
                                    // that.downloadVideo(that.recorder.getBlob());    // indica que a la función downloadVideo le mande el blob obtenido 
                                    // clearInterval(interval)         // limpiamos el intervalo para evitar que siga los ciclos y consuma memoria
                                    var blob = that.recorder.getBlob();
                                    // that.downloadVideo(blob);    // indica que a la función downloadVideo le mande el blob obtenido 
                                    clearInterval(interval)         // limpiamos el intervalo para evitar que siga los ciclos y consuma memoria
                                    that.sendVideoCall(blob, Acreated, Aexpire, isIOS)

                                }
                            }, 1000);   // Es la duración de cada ciclo que va a realizar
                        }, 1000);
                    }
                })
                // Cuando la respuesta manda error
                .catch((error) => {
                    let e = error.toString();
                    
                    if (e.includes("500")) {
                        that.setState ({
                            errorMessage: "Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz.", 
                            errorToken: true, 
                            errorF: "Error en la captura de rostro", 
                            token: null, 
                            loading: false, 
                            hasResponse: true 
                        })
                    } else {
                        that.setState ({
                            errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", 
                            errorToken: true, 
                            errorF: "Error en la captura de rostro", 
                            token: null, 
                            loading: false, 
                            hasResponse: true 
                        })
                    }
                    if (that.state.intents === 3)
                        that.statusF();
                    else 
                    that.statusR();
                })

            }, 'image/jpeg', 0.9);  // Esto determina el tipo de archivo y calidad del mismo Al blob le decimos que nos de un archivo tipo imagen jpeg, con una calidad de 0.9
        }, 3000);
    }

    // Función para descargar el video
    downloadVideo = (blob) => {     // recibe el blob que manda el metodo takePhotoAndroid de la linea 401
        var video = document.createElement('video');
        video.src = URL.createObjectURL(blob);
        console.log("time1", video.duration);
        video.onloadedmetadata = function (e) {
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "test.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            console.log("time2", video.duration);
        }
    }

    obtenerToken = async () => {
        if ((this.state.errorMessage).includes("embedings")) {
            localStorage.removeItem("codes")
        }
        this.setState({ loading: false, errorToken: false, errorMessage: "" })
        //console.log("entre a obtener");
        //this.checkCamera()
        if (localStorage.getItem("bloqueo") === "false") {
            /*             obtenerTokenVideo(this.state.uuid).then(async (response) => {
                            if (response.status === 200) { */
            this.setState({ conteo: true, isStart: false })
            this.elemento.classList.add("slideInDown");
            this.cuenta.classList.add("fadeIn");
            this.cuenta.classList.add("delay-1s");
            this.cuenta.innerHTML = '<img src="images/sand_clock.gif" height="50"alt="" />';

            if (!this.state.codes.length) {
                //si es IOS a takepicture sino a takePhotoAndroid
                if (isIOS || isMobileSafari || isIPad13) {
                    this.takepicture();
                    //console.log("Tus sistema operativo es: ", isIOS);
                } else {
                    this.takePhotoAndroid();
                    //console.log("Tus sistema operativo es: Android ");
                }
            } 
            else if (isIOS || isMobileSafari || isIPad13) {
                setTimeout(async () => {
                    this.cuenta.innerHTML = "";
                    this.cuenta.className = "token_txt animated";
                    this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                    //titulo.innerHTML = "<p>Por favor <b>lee</b> el siguiente texto</p>";
                    this.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true })
                    //const listWord = this.state.token.token.split(" ");
                    //const texto = "Valido el código de seguridad "+ this.state.token.token;
                    //const listWord = texto.split(" ");
                    //const countWord = listWord.length;
                    //let line = "";
                    //let lines = [];
                    //listWord.forEach((element, index) => {
                    //    line = line + " " + element;
                    //    if ((index + 1) % 3 === 0) {
                    //        lines.push(line.trim());
                    //        line = "";
                    //    } else if (countWord - 1 === index + 1) {
                    //        lines.push(line.trim());
                    //        line = "";
                    //    }
                    //});
                    //const token = lines.pop();
                    let tokens_ls = JSON.parse(localStorage.getItem("codes"))
                    let tokenLS = tokens_ls.pop()
                    //console.log(tokenLS);
                    this.setState({ token: tokenLS });
                    localStorage.setItem("codes", JSON.stringify(tokens_ls));
                    const token = tokenLS.code;
                    const connectionClient = new ConnectionClient();
                    let peerConnection2 = null;
                    const that = this;

                    const localVideo = document.getElementById('video_wrt_environment');
                    localVideo.autoplay = true;
                    localVideo.muted = true;

                    async function beforeAnswer(peerConnection) {
                        //cuenta.innerHTML = lines.join("<br>");
                        that.localStream.then(function (mediaStream) {
                            mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                        })
                        peerConnection2 = peerConnection;
                        let dataChannel = null;
                        let interval = null;
                        let timeoutt = null;
                        function onMessage({ data }) {
                            const responseWRT = JSON.parse(data);
                            console.log("responseWRT: ", responseWRT.res);
                            if (responseWRT.status === 200) {
                                if (responseWRT.res.payload.match) {
                                    that.setState({ loading: false })
                                    that.setLocalStorageResultFace(responseWRT.res.payload)
                                    that.sendData(responseWRT.res.payload.match)
                                    setTimeout(() => {
                                        that.props.history.push("/consulta_historial")
                                    }, 300);
                                } else {
                                    that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null })
                                    if (that.state.intents === 3)
                                        that.statusF();
                                    else
                                        that.statusR();
                                }
                                that.setState({ loading: false, intents: 0, hasResponse: true })
                                peerConnection.close()
                            } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                if (that.state.intents === 3)
                                    that.statusF();
                                else
                                    that.statusR();
                                peerConnection.close()
                            } else if (responseWRT.status === 404) {
                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                if (that.state.intents === 3)
                                    that.statusF();
                                else
                                    that.statusR();

                                if (responseWRT.res.message_client === "Spoof detected") {
                                    that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) });
                                }
                                peerConnection.close()
                            } else {
                                that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                if (that.state.intents === 3)
                                    that.statusF();
                                else
                                    that.statusR();
                                peerConnection.close()
                            }
                            that.titulo.innerHTML = "<p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>";
                            that.cuenta.className = "cnt_dwn animated";
                            localVideo.classList.remove("blur_video")
                        }
                        let intentos = 0
                        function onDataChannel({ channel }) {
                            if (channel.label !== 'ping-pong') {
                                return;
                            }
                            dataChannel = channel;
                            dataChannel.addEventListener('message', onMessage);
                            interval = setInterval(() => {
                                intentos++;
                                if (intentos === 1) {
                                    //titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1);
                                }
                                if (intentos === 2) {
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                                }
                                if (intentos === 3) {
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                }
                                if (intentos === 4) {
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                }
                                if (intentos === 6) {
                                    that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });
                                    dataChannel.send("finish_record");
                                    localVideo.classList.add("blur_video")
                                }
                                if (intentos === 7) {
                                    that.setState({ loading: false, hasResponse: false })
                                    that.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                    that.cuenta.innerHTML = "";
                                    that.cuenta.className = "";
                                }
                            }, 1000);
                            timeoutt = setTimeout(() => {
                                let contenedor = (window.location.hostname).includes("proddicio") ? "dco-bucket-prod" : (window.location.hostname).includes("dev.devdicio") ? "dco-bucket-qa" : "dco-bucket-dev";
                                dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: contenedor, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service: 'videotoken-embedding' }));
                            }, 500);
                        }
                        peerConnection.addEventListener('datachannel', onDataChannel);
                        const { close } = peerConnection;
                        peerConnection.close = function () {
                            if (dataChannel) {
                                dataChannel.removeEventListener('message', onMessage);
                            }
                            if (interval) {
                                clearInterval(interval);
                            }
                            if (timeoutt) {
                                clearTimeout(timeoutt)
                            }
                            return close.apply(this, arguments);
                        };
                    }

                    peerConnection2 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                    window.peerConnection2 = peerConnection2;
                }, 1000)
            }
            else {  //AQUI VA SI ES ANDROID Y SIN FOTO
                    //console.log("Entró a: listCodes android. Tus sistema operativo es: Android ");
                    
                    let codigosLS = JSON.parse(localStorage.getItem("codes"))   // Obtenemos el Local Storage
                    //console.log("codigosLS",codigosLS);
                    //.....
                    let tokenLS = codigosLS.pop();  // Creamos una nueva variable y le agregamos el "listCode" del ultimo arrreglo (pop: significa que va a agarrrar el ultimo arreglo)
                    //console.log("Códigos", tokenLS);
                    this.setState({ token: tokenLS });
                    localStorage.setItem("codes", JSON.stringify(codigosLS));

                    const token = tokenLS.code;     // Tomamos la variable TokenLs y obtenemos el code que viende dentro del objeto deel ultmio arreglo y lo guardamos en una nueva variable
                    //console.log("token.code", tokenLS.code);
                    const Acreated = tokenLS.created;
                    //console.log("tokenLS.created", tokenLS.created);
                    const Aexpire = tokenLS.expire;
                    //console.log("tokenLS.expire", tokenLS.expire);

                    const localVideo = document.getElementById('video_wrt_environment');    // obtenemos la etiqueta con el id marcado y lo guardamos en una variable
                            localVideo.autoplay = true;         // al local video le indicamos que puede grabar
                            localVideo.muted = true;            // al local video le indicamos que esté en silencio

                    setTimeout(async () => {                    // Creamos una función asincrona
                                        // this.cuenta es un elemento que obtiene la etiiqueta con el id números
                        this.cuenta.innerHTML = "";             // that ahora es nuestro contexto y le indica que
                        this.cuenta.className = "token_txt animated";   // A ese elemento se le agrega la clase marcada
                        this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";    // Creamos un tittulo en el hmtl que muestra este texto
                        this.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true });      // banderas para inicializar
                        //console.log("Estamos dentro del constructor de numeros aleatorios");

                        this.recorder.startRecording();     // Indicamos que empiece a grabar, declaramos la funcion en la linea: 118
                        let intentos = 0;
                        let interval = setInterval(() => {  // inicia la función  
                            intentos++;                     // declaramos que a cada intento empice en 0 y se sume de uno en uno
                            if (intentos === 1) {           // inicio del primer ciclo
                                this.cuenta.className = "token_nmbr animated fadeIn";   // a la tag que invoca cuenta se le agregan las clases marcadas
                                this.cuenta.innerHTML = token.substring(0, 1);          // lee el numero que esta en el estado 0 y el que esta antes del eestado 1 de la lista code que esta almacenada en la variablee token
                                //console.log("Entró a ciclo 1: ", token);
                            }
                            if (intentos === 2) {           // inicio del segundo ciclo
                                this.cuenta.className = "token_nmbr animated fadeIn";   // a la tag que invoca cuenta se le agregan las clases marcadas
                                this.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);    // Indica que agregue el valor del ciclo anterior y ahora agregue el numero que esta en el estado 1 y el que esta antes del estado 2 de la lista code que esta almacenada en la variablee token
                                //console.log("Entró a ciclo 2: ", token);
                            }
                            if (intentos === 3) {           // inicio del tercer ciclo
                                this.cuenta.className = "token_nmbr animated fadeIn";
                                this.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                //console.log("Entró a ciclo 3: ", token);
                            }
                            if (intentos === 4) {           // inicio del cuarto ciclo
                                this.cuenta.className = "token_nmbr animated fadeIn";
                                this.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                //console.log("Entró a ciclo 4: ", token);
                            }
                            if (intentos === 7) {           // deja pasar el ciclo 5 y 6 hasta que llega al séptimo ciclo
                                this.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });     // deeclara las banderas si son todas correectas
                                
                                localVideo.classList.add("blur_video")  // una vez terminados los ciclos, le manda la clase (borrosa) al video 
                                this.setState({ loading: false, hasResponse: false })   
                                this.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";   // Cambia el textos
                                this.cuenta.innerHTML = "";     // como ya no hay numeros los cambiamos a vacio
                                this.cuenta.className = "";     // Tambien le quitamos la clase
                                this.recorder.stopRecording(function () {   // indicamos que termine de grabar el video
                                    //console.log("Ha terminado la grabación");
                                });
                            }
                            if(intentos === 8){     // cuando llega al ciclo 8 
                                var blob = this.recorder.getBlob();
                                // this.downloadVideo(blob);    // indica que a la función downloadVideo le mande el blob obtenido 
                                clearInterval(interval)         // limpiamos el intervalo para evitar que siga los ciclos y consuma memoria
                                this.sendVideoCall(blob, Acreated, Aexpire, isIOS)
                            }
                        }, 1000);   // Es la duración de cada ciclo que va a realizar
                    }, 1000);
                // this.takePhotoAndroid();
            }

            //}) 
        } else {
            this.setState({ isStart: false })
        }
    }

    sendVideoCall = (blob, Acreated, Aexpire, isIOS ) => {      // Todos los parametros que enviamos en SendVideoCall de la funcion de android, esta función los recibe
        
        enviarVideoRostro(blob, Acreated, Aexpire, isIOS)
                .then((responseWRT) => {
                    //this.getTiempo()
                    //console.log("Un log antes de 200 responsePAYLOAD", responseWRT.data.payload);
                    if (responseWRT.status === 200) {
                        //console.log("Entró a status 200");
                        //AQUI ENVIA A LA SIGUIEENTE PAGINA
                        // if (response.data.payload.match) {
                            this.setState({ loading: false })
                            // this.setLocalStorageResultFace(response.data.payload)
                            this.sendData(responseWRT.data.payload.match)
                            //console.log("responseWRT.data.payload.match", responseWRT.data.payload.match);
                            setTimeout(() => {
                                this.props.history.push("/consulta_historial")
                            }, 300);
                        // } else {
                            // this.setState({ errorMessage: response.data.payload.message, errorToken: true, errorF: "Error en la captura", token: null })
                            // if (that.state.intents === 3)
                            //     that.statusF();
                            // else
                                // that.statusR();
                        // }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("Error:", error.response);
                        let eMessage = "Asegúrate de colocar tu rostro dentro del área y pronunciar correctamente."
                        let mensaje = ""
                        if (error.response.data) {
                            mensaje = error.response.data.message_client
                        }
                        if (error.response.status === 500 || error.response.status === 400) {
                            console.log("Error de estatus 500 y 400");
                            if (mensaje === "No se encontro rostro") {
                                eMessage = msjRostro
                            } else if (mensaje === "Se detecto cubrebocas") {
                                eMessage = msjCubrebocas
                            } else if (mensaje === "Condiciones de luz no adecuadas") {
                                eMessage = msjLuz
                            } else if (mensaje === "Imagen borrosa") {
                                eMessage = msjBorrosa
                            } else if (mensaje === "Se detectaron multiples caras") {
                                eMessage = msjMultiple
                            } else if (mensaje === "No logramos entender la frase, intente nuevamente") {
                                eMessage = msjFrase
                            } else if (mensaje === "Falta de luz") {
                                eMessage = msjObscuridad
                            } else if (mensaje === "Posicion de cara incorrecta") {
                                eMessage = msjDistancia
                            } else if (mensaje === "Error frase") {
                                eMessage = msjFrase2
                            } else if (mensaje === "No hay movimiento") {
                                eMessage = msjMov
                            }
                            this.setState({ errorMessage: eMessage, errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        } else if (error.response.status === 404) {
                            console.log("Error de estatus 404");
                            this.setState({ errorMessage: msjFrase2, errorToken: true, errorF: "No se detecto audio", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        } else {
                            this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        }
                    }
                    this.setState({ errorCapture: true });
                    document.getElementById('video_wrt_environment').classList.remove("blur_video");
                })
    }

    verificarToken = async (video) => {
        this.setState({ loading: true, hasResponse: false })
        verificarTokenVideo(this.state.uuid, this.state.token, video, isIOS, isMobileSafari).then((response) => {
            console.warn(response);
            if (response.status === 200) {
                if (response.data.payload.match) {
                    this.setState({ loading: false })
                    this.setLocalStorageResultFace(response.data.payload)
                    that.sendData(responseWRT.res.payload.match)
                    setTimeout(() => {
                        this.props.history.push("/consulta_historial")
                    }, 300);
                } else {
                    this.setState({ errorMessage: response.data.payload.message, errorToken: true, errorF: "Error en la captura", token: null })
                    if (that.state.intents === 3)
                        that.statusF();
                    else
                        that.statusR();
                }
            }
        }).catch((error) => {
            if (error.response.hasOwnProperty('status')) {
                if (error.response.status === 500) {
                    this.setState({ errorMessage: error.response.data.payload.message })
                } else if (error.response.status === 404) {
                    this.setState({ errorMessage: error.response.data.payload.message })
                    if (error.response.data.payload.message === "Spoof detected") {
                        this.setState({ intents: 5 });
                    }
                } else {
                    this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve" })
                }
            } else {
                this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve" })
            }
            this.setState({ errorToken: true, errorF: "Error en la captura", token: null })
            if (that.state.intents === 3)
                that.statusF();
            else
                that.statusR();
            console.error(error.response)
        }).finally(() => {
            this.setState({ loading: false, intents: this.state.intents + 1, hasResponse: true })
        })
    }

    setLocalStorageResultFace = (result) => {
        let face = {}
        //console.log("responseWRT: ", result);
        if (result.match) {
            face = {
                match: result.match,
                gender: (result.faceComparison.payload.Query[0].gender) === "male" ? "Masculino" : "Femenino",
                age: result.faceComparison.payload.Query[0].age.toFixed(0),
                expressions: result.faceComparison.payload.Query[0].expressions,
                liveness: result.liveness
            }
        } else {
            face = {
                match: false,
                gender: "Indeterminado",
                age: 0,
                expressions: {
                    happy: 0.0
                },
                liveness: "No superada"
            };
        }//liveness
        localStorage.setItem("face", JSON.stringify(face));
    };

    stopAnimation = () => {
        this.state.token = null;
    };

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("adios 🐶");
        });
    }

    sendData = (match) => {
        statusData("captura_video_token", { coincidencia: match, speech: true })
    }

    statusE = () => {
        //console.log("entre a status cancelar");
        statusError("Captura Video Token", this.state.errorF, "cancelado")
        setTimeout(() => {
            window.close()
        }, 300);
    }

    intentosBloqueo = () => {
        localStorage.setItem("bloqueo", "true");
        this.statusE();
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime)
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));

    }

    statusF = () => {
        //console.log("entre a status ine");
        statusError("Captura Video Token", this.state.errorF, "error")
    }

    checkCamera = () => {
        setTimeout(() => {
            //console.log("Camera:"+this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    statusR = () => {
        statusReintento("Captura Video Token", this.state.errorF, "RETRY");
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <div>
                {(this.state.errorToken && this.state.intents < Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"))) ?
                    <div className="modal fade show" style={{ display: "block" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Algo sucedio</h5>
                                    <Link to="/cancelar_flujo/video_token" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p>{this.state.errorMessage}</p>
                                    {/* <p>Lo sentimos <b>no pudimos</b> reconocer la <b>lectura del texto</b>, por favor <b>intenta de nuevo</b> o cancela para abandonar el proceso.</p> */}
                                </div>
                                <div className="modal-footer">
                                    <Link to="/cancelar_flujo/video_token" className="btn btn-secondary" data-dismiss="modal">Cancelar</Link>
                                    <button type="button" onClick={this.obtenerToken} className="btn btn-primary btn-success">Reintentar</button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame speech_mod">
                        <video src="" id="video_wrt_environment" playsInline muted></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                        {/*  <div data-vjs-player>
                            <video id="myVideo2" ref={node => this.videoNode = node} className={["video-js", "video-js", ((this.state.isComplete) ? "blur_video" : "")].join(" ")} playsInline></video>
                        </div> */}
                    </div>
                    <div className="module_gradident_overlay"></div>
                    <div className="module_title animated slideInDown">
                        {(this.state.isComplete) ?
                            (<p>Gracias por tu <b>paciencia</b></p>) : (<p>Ubica tu <b>rostro</b> dentro de la <b>guía</b></p>)}
                    </div>
                    <img className="id_mask animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/face_id_on.svg'} alt="" />
                    <div className="align_alert hide_elmnt"><p>Mantén tu rostro dentro del área definida para iniciar la lectura.</p>
                    </div>

                    <div className="recorder_container">
                        {(this.state.isComplete) ?
                            <div className="recorder_box">
                                <div className="circle_back" onClick={this.obtenerToken} >
                                    <div className="circle_front"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul>
                                        <li className="b_a"></li>
                                        <li className="b_b"></li>
                                        <li className="b_c"></li>
                                        <li className="b_d"></li>
                                        <li className="b_e"></li>
                                        <li className="b_f"></li>
                                        <li className="b_g"></li>
                                        <li className="b_h"></li>
                                        <li className="b_i"></li>
                                        <li className="b_j"></li>
                                        <li className="b_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div> : ("")}

                        {(this.state.isRecording) ? <div>
                            <div className="recorder_box">
                                <div className="circle_back recording_state" onClick={this.stopRecord}>
                                    <img className="hide_elmnt" src={process.env.PUBLIC_URL + '/images/warning_36.png'} width="35" alt="" />
                                    <div className="circle_front animated infinite pulse"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul className="rec_state">
                                        <li className="b_a"></li>
                                        <li className="b_b anim_b"></li>
                                        <li className="b_c anim_c"></li>
                                        <li className="b_d anim_d"></li>
                                        <li className="b_e anim_e"></li>
                                        <li className="b_f anim_f"></li>
                                        <li className="b_g anim_g"></li>
                                        <li className="b_h anim_h"></li>
                                        <li className="b_i anim_i"></li>
                                        <li className="b_j anim_j"></li>
                                        <li className="b_k anim_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div>
                        </div> : ("")}
                    </div>
                    {(this.state.isRecording) ? <div className="status_txt">
                        <p>escuchando</p>
                    </div> : ("")}
                </div>
                {(this.state.isStart) ? <Fragment> <div className="overlay_box">
                    <div className="alert_box">
                        <p className="animated slideInDown">Por favor <b>mantén tu rostro visible</b> dentro de la guía, <b>evita contraluz,</b> sin lentes, gorra, ni más personas en la toma y <b>lee el texto</b> en el orden que te será indicado.</p>
                        <button type="button" onClick={this.obtenerToken} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">SIGUIENTE</button>
                    </div>
                </div></Fragment> : ("")}

                <div id="cuadro" className="red_flag_karaoke vsbl_bx animated">
                    <div id="head_shop" className="head_shop">
                        <p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>
                    </div>
                    <div className="center_nmbrs">
                        <div className="nmbr_bx">
                            <p id="numeros" className="">3</p>
                        </div>
                    </div>
                    <div className="red_flg"><img src="images/red_flag.svg" alt="" /></div>
                </div>

                {this.state.intents >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) ?
                    (<div className="err_finale  animated slideInUp"> {this.intentosBloqueo()}
                        <div className="center_checks">
                            <h5>Proceso no exitoso</h5>
                            <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                            <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuid).split("-")[0]}</b> </p>
                            <br />
                            {
                                (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") ?
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <p><b>Llámanos al:</b></p>
                                                <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                            </div>
                                            <div className="col">
                                                <p><b>Escríbenos a:</b></p>
                                                <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className="action_buttons noscroll_screen">
                                {
                                    <Link to={"/" + this.state.apikey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                                }
                            </div>
                        </div>
                    </div>) : ("")}
                {(this.state.isComplete && !this.state.hasResponse) ? <Loader /> : ("")}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>) : ("")
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animated slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                window.location.reload()
                                //this.props.history.push("/identificacion")
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        );
    }
}
CameraVideoTokenIOS = withOrientationChange(CameraVideoTokenIOS);
export default withRouter(CameraVideoTokenIOS);