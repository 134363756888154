import {
    actualizarEstado
} from '../services/api.js';
import {
    v4 as uuidv4
} from 'uuid';
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';

let objetoFinal = {};

export const agregarInfo = (dataUser, elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const elementoEncontrado = dataUserLS.findIndex(function (item) {
        return item.description === elemento.description
    })
    ////console.log("elementoEncontrado" + elementoEncontrado + " item:"+elemento);
    if (elementoEncontrado !== -1) {
        dataUserLS[elementoEncontrado] = elemento
    } else {
        dataUserLS.push(elemento)
    }
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const esEditable = () => {
    //lso campos son editables para CDC y otorgantes
    const uuidExcepcion = ["0zmV8ljIWoFfGsaieC4vuEPwpVKYYrAP", "MTMba54LICmL2Boez3KuFdVL1vD1boKk", "7a531a75-e0cf-4158-bd99-86b733ef22ad", "94b502fd-5546-44e1-b12f-2122d410cea0"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    //console.log("respuesta", respuesta);
    return respuesta
}

export const initStatus = (nombreOtorgante) => {
    //console.log("entre a initStatus");
    updateInitStatus(nombreOtorgante);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: "inicio_page",
            result: "OK",
            //data: { transaccion: localStorage.getItem("uuidTrx")}
        }), new URL(window.location).searchParams.get("referer"))
        //console.log("hay referer");
        window.opener.postMessage(JSON.stringify({
            step: "inicio",
            result: "OK",
            data: {
                transaccion: localStorage.getItem("uuidTrx")
            }
        }), new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt"))
    }
    if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
    if (new URL(window.location).searchParams.get("schema")) {
        localStorage.setItem("package", new URL(window.location).searchParams.get("schema"))
    }
}

const updateInitStatus = async (nombreOtorgante) => {
    if (localStorage.getItem("completado") === "false") {
        let trx_id = localStorage.getItem("uuidTrx");
        if (trx_id === null) {
            //console.log("se creo nuevo trx");
            trx_id = uuidv4();
            localStorage.setItem("uuidTrx", trx_id);
        }
        let json = {
            "status": "OK",
            "step": "inicio"
        }
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        let event = JSON.stringify(json);
        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'step': "inicio_page",
            'event': event,
            'origin': "celd",
            'nombreOtorgante': nombreOtorgante
        }
        //console.log("uuidtx:", localStorage.getItem("uuidTrx"));
        actualizarEstado(objetoStatus).then((data) => {
            if (data.status === 200) {
                //console.log("Se actualizo status INIT");
            }
        }).catch((e) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
        })
    } else {
        return
    }
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK"
        }), localStorage.getItem("externalReference"))
    }
}

export const statusData = (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK",
            data: datos
        }), localStorage.getItem("externalReference"))
    }
}

export const statusError = (paso, datos, tipo) => {
    //console.log("entre a status cancelar");
    var json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            status: status,
            type: "PROCESS",
            error_text: datos,
        }), localStorage.getItem("externalReference"))
        if (tipo === "cancelado")
            window.close()
    }
}

const updateStatus = async (objetoInformacion) => {
    if (localStorage.getItem("completado") === "false") {
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        const bandera = localStorage.getItem("flag");

        let nombre = dataUser ? obtenerValorUser(dataUser, "nombre", "") + " " : '';
        let apellidoPaterno = dataUser ? obtenerValorUser(dataUser, "apellidoPaterno", "") + " " : '';
        let apellidoMaterno = dataUser ? obtenerValorUser(dataUser, "apellidoMaterno", "") : '';
        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo =  localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let fullName = nombre + apellidoPaterno + apellidoMaterno;
        let flag = bandera ? bandera : '';

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        actualizarEstado(objetoFinal).then((data) => {
            if (data.status === 200) {
                //console.log("Se actualizo status:",event_t);
            }
        }).catch((e) => {
            console.log("No se pudo actualizar Status. " + e);
            //setLoading(false)
        })
    } else {
        return
    }
}

/* Validaciones */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z\s]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    var expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

export const statusReintento = (paso, datos, tipo) => {
    var json = {};
    let status = tipo
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)
    updateStatus(paso, event_t);
}

export const sendData = (objetoStatus) => {
    updateStatus(objetoStatus);
}