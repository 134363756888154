import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { enviarCorreoAceptacion, obtenerScore, crearSMS, actualizarEstado } from '../services/api.js'
import { configStyle, obtenerValorConfig, obtenerValorUser, replaceValue } from '../services/configStyle'
import Loader from "../components/loader";
import { isMobile } from 'react-device-detect';
import { hayExcepcion, status, statusError, statusData } from '../services/data'

let ConsultaHistorial = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [statError, setstatError] = useState(false);
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState(null)
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataFace, setDataFace] = useState({})
    const [correo, setCorreo] = useState('')
    const [correoMsj, setCorreoMsj] = useState(false)
    const [changeStyleValue, setChangeStyleValue] = useState(false)
    const [focusCorreo, setFocusCorreo] = useState(false)
    const [inputActive, setInputActive] = useState(false)
    const [isGreenFlag, setGreenFlag] = useState(true)
    const [contador, setContador] = useState(0)
    const { ruta } = useParams();
    const [flag, setFlag] = useState(false);


    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS != null) {
            setDataFace(JSON.parse(dataFaceLS))
        }

        obtenerScoreUser()
        status("consulta_score_page", "Validación de Score")

    }, [])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const printText = () => {
        let textToPrint = "";
        let textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_3")
        if (textStorage !== "") {
            textToPrint = replaceValue(textStorage, "{USUARIO}", obtenerValorUser(dataUser, "nombre"))
            textToPrint = replaceValue(textToPrint, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"))
        } else {
            textToPrint = "Resultados de Datos Biometricos capturados:"
        }
        return textToPrint
    }

    useEffect(() => {
        //console.log("contador", contador);
        if (!flag && parseInt(contador) < 10 && parseInt(contador) > 0) {
            obtenerScoreUser()
        } else {
            if (parseInt(contador) > 10) {
                console.log("sin dato en bandera");
                setGreenFlag(false)
                statusError("score_check", "Sin valores en el score", "error")
            }
            setLoading(false)
        }
    }, [contador])

    const obtenerScoreUser = async () => {
        setLoading(true)
        let uuidUser = localStorage.getItem("uuidUser")
        //console.log("entre a obtener");
        obtenerScore(uuidUser, !isMobile).then((response) => {
            //console.log("Response200",response);
            if (response.status === 200) {
                //console.log("200:", response.data.payload);
                if (response.data.payload.flag) {
                    if (response.data.payload.flag !== "green") {
                        setGreenFlag(false)
                    }
                    let data = { flag: response.data.payload.flag }
                    statusData("consulta_score", data)
                    setLoading(false)
                    setFlag(true);
                    let campos = JSON.stringify(response.data.payload.Fields)
                    localStorage.setItem("fields", campos);
                    localStorage.setItem("flag",response.data.payload.flag);
                } else {
                    console.log("sin bandera");
                    setContador(parseInt(contador) + 1)
                }
            }
        }).catch((error) => {
            console.log("errorBandera:", error);
            setGreenFlag(false)
            setContador(parseInt(contador) + 1)
        })
    }

    const statusF = (error) => {
        //console.log("entre a status ine");
        statusError("email_check", error, "error")
    }

    const enviarMovil = async () => {
        setLoading(true)
        localStorage.setItem("movil", correo)
        console.log("El número móvil es: ", correo);
        let area = "52";
        if (hayExcepcion()) {
            area = "51"
        }
        crearSMS(uuid, correo, area).then((response) => {
            if (response.status === 200) {
                setTimeout(() => {
                    history.push('/validar_sms');
                }, 200);
            }
            else {
                //console.log("Ocurrió un error al generar el SMS");
            }
        }).catch((err) => {
            console.error(err);
            //console.log("algun error");
        }).finally(() => {
            setLoading(false)
        })
    }

    const enviarCorreo = (e) => {
        e.preventDefault()
        setLoading(true)
        setCorreoMsj(false)
        localStorage.setItem("correoElectronico", correo);
        let nombre = obtenerValorUser(dataUser, "nombre");
        let mailto = correo;
        let nacimiento = obtenerValorUser(dataUser, "nacimiento");
        let curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
        let otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CONTACTO_PRINCIPAL", "Otorgante");
        let vigencia = obtenerValorUser(dataUser, "vigencia");
        let coincidencia = (dataFace.match ? "Coincide" : "Requiere validación");
        let liveness = dataFace.liveness;
        let textoConsentimiento = printText();
        let dataJSONr = convertdataJSON();
        let dataJSON = JSON.stringify(dataJSONr);
        ////console.log("dataJSON",dataJSON);
        enviarCorreoAceptacion({ uuid, mailto, nombre, nacimiento, curp, otorgante, vigencia, coincidencia, liveness, textoConsentimiento, apiKey, dataJSON }).then((response) => {
            if (response.status === 200) {
                //console.log(response);
                if (response.data.payload.success) {
                    statusData("email_check", { email: mailto })
                    setTimeout(() => {
                        history.push('/finalizado');
                    }, 200);
                } else {
                    setLoading(false)
                    setstatError(true)
                    statusF(response.data.payload);
                }
            }
            else {
                setstatError(true)
                statusF(response.data.payload);
            }
        }).catch((err) => {
            console.error(err);
            //console.log("algun error");
            setstatError(true)
            statusF(err);
        }).finally(() => {
            setLoading(false)
        })
    }

    const enableFullScreenSpace = () => {
        setChangeStyleValue(false)
    }

    const convertdataJSON = () => {
        var fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        var jsonObj = {};
        jsonObj.uuidUser = uuid;
        let uuidC = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "") + "_" + fecha + "_" + uuid.split("-")[0];
        jsonObj.custom_uuid = uuidC.split(" ").join("_");
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user"));
        jsonObj.email = correo;
        var res = JSON.stringify(jsonObj)
        //jsonObj = JSON.parse(jsonObj);
        ////console.log(res);
        return jsonObj;
    }

    const statusE = () => {
        let data = "Cancelado"
        statusError("Validación de Score", data, "cancelado")
        setTimeout(() => {
            window.close()
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
        }, 300);
    }


    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="consulta_historial" />
                <div className="main_text_container">
                    <h1 className="animated">Aceptación de consulta de historial crediticio</h1>
                    {(isGreenFlag) ? 
                        <p className="animated">En este momento enviaremos una <b>copia </b>
                        de la <b>autorizacion</b> del documento <b>informacion {obtenerValorUser(dataUser, "curp")}.pdf </b>
                        al siguiente correo por favor <b>verifica</b> que sea correcto:</p> 
                        : 
                        <p>Haz <b>completado</b> el proceso, desafortunadamente debemos <b>validar</b> con un asesor por favor ingresa tu <b>número móvil</b> para comunicarnos contigo. </p>}

                </div>
                <form>
                    <div className="ocr_data_display">

                        <div className={["form-group", "bmd-form-group", (focusCorreo ? "is-focused" : "")].join(" ")}>
                            <label htmlFor="emailInput" className="bmd-label-floating">{(isGreenFlag ? "Correo electrónico:*" : "Número móvil:")}</label>
                            <input type="email" inputMode="email" name="correo" className="form-control" id="emailInput" defaultValue={correo} onChange={e => setCorreo(e.target.value)} onFocus={e => {
                                setFocusCorreo(true)
                                //console.log(inputActive);
                                setInputActive(true)
                            }} onBlur={e => {
                                enableFullScreenSpace()
                                if (correo === "") {
                                    setFocusCorreo(false)
                                }
                                setInputActive(false)
                            }} />
                        </div>

                    </div>
                    <div className={(changeStyleValue ? "action_buttons" : "action_buttons noscroll_screen")}>
                        <button type="button" onClick={e => setShowCancel(true)} className="btn btn-primary forcewidth100 main_color">
                            CANCELAR</button>
                        <br />
                        <button type="button" onClick={(e) => { if (isGreenFlag) { setCorreoMsj(true) } else { enviarMovil(); setCorreoMsj(false) } }} disabled={!correo} className={(!isGreenFlag) ? "btn btn-raised btn-secondary forcewidth100 main_bg_color " : "btn btn-raised btn-primary forcewidth100 main_bg_color"}>CONTINUAR</button>
                        {(!isGreenFlag) ? <Link to={"/" + apiKey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">INTENTAR NUEVAMENTE</Link> : ("")}
                    </div>
                </form>
                {(correoMsj) ? (<div className="err_finale  animated slideInUp">
                    <div className="center_checks">
                        <h5>Verifica tu información</h5>
                        <p>En este momento enviaremos el reporte al correo <b>{correo}</b> por favor asegúrate que es correcto o regresa a corregirlo antes de enviarlo.
                        </p>
                        <br />
                        <div className="action_buttons noscroll_screen">
                            <button type="button" onClick={e => setCorreoMsj(false)} className="btn btn-primary forcewidth100 main_color">REGRESAR</button>
                            <button type="button" onClick={enviarCorreo} disabled={!correo} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENVIAR</button>

                        </div>
                    </div>
                </div>) : ("")}
                <Footer />

            </div>
            {(loading) ? <Loader /> : ("")}
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {(statError) ?
                <div className="overlay_box">
                    <div className="alert_box">
                        <p className="animated slideInDown">Verifica tu <b>conexión de internet</b> para volver a enviar el correo y vuelve a intentar</p>
                        <button type="button" onClick={(e) => {
                            setstatError(false); setLoading(false);
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">VOLVER A INTENTAR</button>
                    </div>
                </div>
                :
                ""
            }
        </Fragment>
    )
}

export default ConsultaHistorial