import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import { obtenerValorConfig, obtenerValorUser } from '../services/configStyle'
import { configStyle } from '../services/configStyle'
import Footer from "../components/footer";
import { obtenerCP, actualizarScore} from '../services/api';
import { status, statusData, agregarInfo } from '../services/data'

let IdentificacionEdit = (props) => {
    const history = useHistory();
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [codigoPostal, setCodigoPostal] = useState("")
    const [focusCodigoPostal, setFocusCodigoPostal] = useState(false)
    const [pais, setPais] = useState("")
    const [focusPais, setFocusPais] = useState(false)
    const [estado, setEstado] = useState("")
    const [focusEstado, setFocusEstado] = useState(false)
    const [delegacion, setDelegacion] = useState("")
    const [focusDelegacion, setFocusDelegacion] = useState(false)
    const [colonia, setColonia] = useState("")
    const [colonia2, setColonia2] = useState("Otro")
    const [focusColonia, setFocusColonia] = useState(false)
    const [calle, setCalle] = useState("")
    const [focusCalle, setFocusCalle] = useState(false)
    const [numeroExterior, setNumeroExterior] = useState("")
    const [focusNumeroExterior, setFocusNumeroExterior] = useState(false)
    //const [numeroInterior, setNumeroInterior] = useState("")
    //const [focusNumeroInterior, setFocusNumeroInterior] = useState(false)
    const [inputActive, setInputActive] = useState(false)
    const [databack, setDataback] = useState(false)
    const [cambioCP, setCambioCP] = useState(false)
    const [otro, setOtro] = useState(false)
    const [optionsSelect, setoptionsSelect] = useState([])

    useEffect(() => {
        let dataOtorganteLS = "", dataUserLS = "", dataOCRFrontLS = "";
        dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        dataOCRFrontLS = localStorage.getItem("ocrFront")
        if (dataOCRFrontLS != null) {
            const ocrFront = JSON.parse(dataOCRFrontLS)
            //setDataOCRFront(ocrFront)
            setCodigoPostal(ocrFront.cp || "")
            setPais("México")
            setCalle(ocrFront.calle || "")
            setNumeroExterior(ocrFront.numero?ocrFront.numero.trim():ocrFront.numero || "")
            setEstado(ocrFront.estado || "")
            setDelegacion(ocrFront.municipio || "")
            setColonia(ocrFront.colonia || "")
            setDataback(true)
        }
        /*dataOCRBackLS = localStorage.getItem("ocrBack")
        if (dataOCRBackLS != null) {
            const ocrBack = JSON.parse(dataOCRBackLS)
            setDataOCRBack(ocrBack)
        }*/
        status("datos_direccion_ocr_page", "Datos de direccion OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (codigoPostal.length === 5 && !databack && cambioCP) {
            setCambioCP(false)
            obtenerCP(codigoPostal, localStorage.getItem("uuidUser")).then((response) => {
                if (response.status === 200) {
                    setCodigoPostal(codigoPostal);
                        var dir = response.data.payload.address[0];
                        var delegacionW = dir.municipio
                        var estadoW = dir.estado
                        var coloniaW = dir.asentamiento
                        let arreglo = [];
                        let tam = (response.data.payload.address).length
                        if(tam>1){
                            for(let i = 0; i<tam; i++){
                                let colonia = response.data.payload.address[i].asentamiento
                                arreglo.push(colonia);
                            }
                        }else{
                            let colonia1 = dir.asentamiento
                            arreglo.push(colonia1);
                        }
                        setoptionsSelect(arreglo)
                        setColonia(coloniaW)
                        setDelegacion(delegacionW)
                        setEstado(estadoW)
                        setPais("México")
                }
            }).catch(() => {
                //console.log("algun error");
                if (inputActive) {
                    //console.log("inputActive algun error",inputActive);
                }
                //setLoading(false)  identificacion_edit
            })
            setTimeout(() => {
                setCodigoPostal(codigoPostal);
            }, 1000);
        }
    }, [codigoPostal])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
        /*       if (codigoPostal === "")
                  setCodigoPostal(obtenerValorUser(dataUser, "codigoPostal", ""))
              if (pais === "")
                  setPais(obtenerValorUser(dataUser, "pais", ""))
              if (estado === "")
                  setEstado(obtenerValorUser(dataUser, "estado", ""))
              if (delegacion === "")
                  setDelegacion(obtenerValorUser(dataUser, "delegacion", ""))
              if (colonia === "")
                  setColonia(obtenerValorUser(dataUser, "colonia", ""))
              if (calle === "")
                  setCalle(obtenerValorUser(dataUser, "calle", ""))
              if (numeroExterior === "")
                  setNumeroExterior(obtenerValorUser(dataUser, "numeroExterior", ""))
              setNumeroInterior(obtenerValorUser(dataUser, "numeroInterior", "")) */
    }, [dataUser])

    useEffect(() => {
        //console.log("datos:",optionsSelect);
    }, [colonia, delegacion, estado, optionsSelect])

    const actualizarDatos = (e) => {
        e.preventDefault()
        agregarInfo(dataUser, { description: "codigoPostal", value: codigoPostal });
        agregarInfo(dataUser, { description: "pais", value: pais });
        agregarInfo(dataUser, { description: "estado", value: estado });
        agregarInfo(dataUser, { description: "delegacion", value: delegacion });
        agregarInfo(dataUser, { description: "calle", value: calle });
        agregarInfo(dataUser, { description: "numeroExterior", value: numeroExterior });
        agregarInfo(dataUser, { description: "calleCompleta", value: calle + " " + numeroExterior });
        let colonia1 = colonia;
        let infoFormulario = []
        infoFormulario.push({ description: "codigoPostal", value: codigoPostal })
        infoFormulario.push({ description: "pais", value: pais })
        infoFormulario.push({ description: "estado", value: estado })
        infoFormulario.push({ description: "delegacion", value: delegacion })
        if (colonia != "Otro") {
            infoFormulario.push({ description: "colonia", value: colonia })
            agregarInfo(dataUser, { description: "colonia", value: colonia });
        } else {
            infoFormulario.push({ description: "colonia", value: colonia2 })
            agregarInfo(dataUser, { description: "colonia", value: colonia2 });
            colonia1 = colonia2
        }
        infoFormulario.push({ description: "calle", value: calle })
        infoFormulario.push({ description: "numeroExterior", value: numeroExterior })
        infoFormulario.push({ description: "calleCompleta", value: calle + " " + numeroExterior })
        //infoFormulario.push({ description: "numeroInterior", value: numeroInterior })
        //console.log(infoFormulario);
        //setDataUser(dataUser.concat(infoFormulario))
        e.target.reset()
        //actualizarScore
        /*
        let calle1 = obtenerValorUser(dataUser, "calle");
        let colonia1 = obtenerValorUser(dataUser, "colonia");
        let cp = obtenerValorUser(dataUser, "codigoPostal");
        let estado1 = obtenerValorUser(dataUser, "estado");
        let municipio = obtenerValorUser(dataUser, "delegacion");
        let numeroExt = obtenerValorUser(dataUser, "numeroExterior");
        */
        let uuid = localStorage.getItem("uuidUser");
        let uuidOtorgante = localStorage.getItem("apikeyOtorgante");
        let empresa = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""));

        actualizarScore(uuid, calle, colonia1, codigoPostal, estado, delegacion, numeroExterior, empresa).then((response) => {
            if (response.status === 200) {
                //console.log(response.data);
                sendData(infoFormulario)
                setTimeout(() => {
                    if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                        history.push('/clausula');
                    } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                        history.push("/autorizacion_solicitud");
                    } else {
                        if (uuidOtorgante === "iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt" || uuidOtorgante === "77b6a48f-eaf8-4b18-8808-374eaa1a3f2f") {
                            setTimeout(() => {
                                history.push('/finalizado');
                            }, 200);
                        } else {
                            history.push("/video_token");
                        }
                    }
                }, 200);
            }
        }).catch((err) => {
            console.error(err);
            //console.log("algun error actualizarScore");
            sendData(infoFormulario)
            setTimeout(() => {
                if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                    history.push('/clausula');
                } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                    history.push("/autorizacion_solicitud");
                } else {
                    if (uuidOtorgante === "iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt") {
                        setTimeout(() => {
                            history.push('/finalizado');
                        }, 200);
                    } else {
                        history.push("/video_token");
                    }
                }
            }, 200);
        })
    }

    const verificarCP = (e) => {
        setCodigoPostal(e)
        setDataback(false)
        setCambioCP(true)
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const revisarColonia = (value) => {
        setColonia(value)
        setColonia2(value)
        if (value === "Otro") {
            setOtro(true)
            setColonia2("")
        } else {
            setOtro(false)
        }
    }

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoDireccion = infoFormulario;
        statusData("datos_direccion_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    return (
        <div className="main_gradient">
            <Header ruta="identificacion_edit" />
            <div className="main_text_container">
                <h1 className="animated">Por favor verifica y completa tu dirección</h1>
                <p className="animated">Es muy importante <b>verificar</b> que la información capturada <b>sea fiel</b> a la información de tu <b>documentación</b> así podremos <b>verificarla</b> fácilmente.</p>
            </div>

            <div className="ocr_data_display">
                <form onSubmit={actualizarDatos}>
                    <div className="row_ext">
                        <div className={["form-group", "bmd-form-group", "col-50", ((focusCodigoPostal || codigoPostal !== "") ? "is-focused" : "")].join(" ")}>
                            <label htmlFor="inputCP" className="bmd-label-floating">Código Postal:</label>
                            <input type="number" max="99999" className="form-control" id="inputCP" defaultValue={codigoPostal} onChange={e => verificarCP(e.target.value)} onFocus={e => {
                                setFocusCodigoPostal(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (codigoPostal === "") {
                                    setFocusCodigoPostal(false)
                                }
                                setInputActive(false)
                            }
                            }
                                maxLength="5" onInput={e => maxLengthCheck(e)} />
                        </div>
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusPais || pais !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputPais" className="bmd-label-floating">País:*</label>
                        <input type="text" className="form-control" id="inputPais" defaultValue={pais} onChange={e => setPais(e.target.value)} onFocus={e => {
                            setFocusPais(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (pais === "") {
                                setFocusPais(false)
                            }
                            setInputActive(false)
                        }} />
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusEstado || estado !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputRegion" className="bmd-label-floating">Estado / Provincia / Región:*</label>
                        <input type="text" className="form-control" id="inputRegion" defaultValue={estado} onChange={e => setEstado(e.target.value)} onFocus={e => {
                            setFocusEstado(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (estado === "") {
                                setFocusEstado(false)
                            }
                            setInputActive(false)
                        }} />
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusDelegacion || delegacion !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputDel" className="bmd-label-floating">Delegación / Municipio:*</label>
                        <input type="text" className="form-control" id="inputDel" defaultValue={delegacion} onChange={e => setDelegacion(e.target.value)} onFocus={e => {
                            setFocusDelegacion(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (delegacion === "") {
                                setFocusDelegacion(false)
                            }
                            setInputActive(false)
                        }} />
                    </div>
                    {(databack) ?
                        <div className={["form-group", "bmd-form-group", ((focusColonia || colonia !== "") ? "is-focused" : "")].join(" ")}>
                            <label htmlFor="inputCol" className="bmd-label-floating">Colonia / Asentamiento:*</label>
                            <input type="text" className="form-control" id="inputCol" defaultValue={colonia} onChange={e => setColonia(e.target.value)} onFocus={e => {
                                setFocusColonia(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (colonia === "") {
                                    setFocusColonia(false)
                                }
                                setInputActive(false)
                            }} />
                        </div>
                        :
                        <div className={["form-group", "bmd-form-group", "is-focused"].join(" ")}>
                            <label htmlFor="inputColS" className="bmd-label-floating">Colonia / Asentamiento:*</label>
                            <select className="custom-select" id="inputColS" onChange={e => revisarColonia(e.target.value)} value={colonia}>
                                <option disabled>Seleccionar:</option>
                                {
                                    optionsSelect.map(
                                        (objeto, index) => (
                                            <option key={`${objeto}`} value={`${objeto}`}>{objeto}</option>
                                        )
                                    )
                                }
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                    }
                    {(otro) ?
                        <div className={["form-group", "bmd-form-group", ((focusColonia || colonia !== "") ? "is-focused" : "")].join(" ")}>
                            <label htmlFor="inputCol" className="bmd-label-floating">Colonia / Asentamiento:*</label>
                            <input type="text" className="form-control" id="inputCol" defaultValue={colonia2} onChange={e => setColonia2(e.target.value)} onFocus={e => {
                                setFocusColonia(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (colonia === "") {
                                    setFocusColonia(false)
                                }
                                setInputActive(false)
                            }} />
                        </div>
                        :
                        ""
                    }

                    <div className={["form-group", "bmd-form-group", ((focusCalle || calle !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputCalle" className="bmd-label-floating">Calle:*</label>
                        <input type="text" className="form-control" id="inputCalle" defaultValue={calle} onChange={e => setCalle(e.target.value)} onFocus={e => {
                            setFocusCalle(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (calle === "") {
                                setFocusCalle(false)
                            }
                            setInputActive(false)
                        }} />
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNumeroExterior || numeroExterior !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputNext" className="bmd-label-floating">Número exterior:</label>
                        <input type="number" max="99999" className="form-control" id="inputNext" defaultValue={numeroExterior} onChange={e => setNumeroExterior(e.target.value)} onFocus={e => {
                            setFocusNumeroExterior(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (numeroExterior === "") {
                                setFocusNumeroExterior(false)
                            }
                            setInputActive(false)
                        }} />
                    </div>
                    <div className="break_float"></div>
                    <br /><br />
                    <button type="submit" disabled={!codigoPostal || !pais || !estado || !delegacion || !colonia || !colonia2 || !calle} className={["btn", "btn-raised", "forcewidth100", ((!codigoPostal || !pais || !estado || !delegacion || !colonia || !colonia2 || !calle) ? "btn-secondary" : "btn-primary main_bg_color")].join(" ")}>CONTINUAR</button>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default IdentificacionEdit